import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';
import { GiMusicalScore } from "react-icons/gi";
import { Plus, Music, Youtube, FileText } from 'react-feather';
import { getSongURL, signedURL, uploadResourse, addResource, addSong, getTags } from '../../../../Api/lms/apiUtils'; 
import PreviewModal from './songpreview';
import { LuMic2 } from "react-icons/lu";
import { toast } from "react-toastify";
interface Song {
  song_id: number;
  song_name: string;
  song_url: string | null;
  karaoke_url: string | null;
  lyricsFileUrl: any;
  annotationFileUrl: any | null;
notation_link:any;

  
}

function SongsList() {
  const [songs, setSongs] = useState<Song[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredSongs, setFilteredSongs] = useState<Song[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true); // To check if more songs are available
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // State to control preview modal
const [previewUrl, setPreviewUrl] = useState<string | null>(null); 
const [isSaving, setIsSaving] = useState<boolean>(false); // New state to prevent multiple saves
const [tags, setTags] = useState([]); // State to store fetched tags
const [selectedTags, setSelectedTags] = useState<any[]>([]); 

  const [song, setSong] = useState({
    name: '',
    mainLink: '',
    karaokeLink: '',
    lyricsFile: null as File | null,
    annotationFile: null as File | null,
    kidFriendly: false,
    notation_link: '',
   
  });

  const lastSongRef = useRef<HTMLDivElement | null>(null); // Reference to the last element

  // Fetch Songs with pagination
  const fetchSongs = async (pageNumber: number) => {
    setIsLoading(true);
    try {
      const response = await getSongURL(searchQuery, pageNumber, 20);
      const newSongs = response.results || [];

      // Append new songs
      setSongs((prevSongs) => [...prevSongs, ...newSongs]);

      // Check if there are more songs
      if (newSongs.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching songs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Search
  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPage(1);
    setHasMore(true);
    setSongs([]); // Clear current songs

    // Fetch filtered songs
    await fetchSongs(1);
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await getTags(); // No need to pass data for GET
        if (response && response.data) {
          setTags(response.data.tags);
          console.log("Tags fetched:", response.data.tags);
        } else {
          setTags([]);
        }
      } catch (err) {
        console.error('Error fetching tags:', err);
      }
    };
  
    fetchTags();
  }, []);
  
  const handleScroll = () => {
    if (
      lastSongRef.current &&
      lastSongRef.current.getBoundingClientRect().bottom <= window.innerHeight + 100 &&
      hasMore &&
      !isLoading
    ) {
      setPage((prevPage) => prevPage + 1); // Load next page
    }
  };

  const handleCheckboxChange = (tag: string) => {
    setSelectedTags((prev) =>
      prev.includes(tag)
        ? prev.filter((t) => t !== tag)  // Remove tag if already selected
        : [...prev, tag]  // Add tag if not selected
    );
  };
  // Infinite Scroll Effect
  useEffect(() => {
    fetchSongs(page);
  }, [page]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, isLoading]);

  // Add Song logic (abbreviated here)
  const handleAddSong = async () => {
    setIsLoading(true);
    setIsSaving(true); 
    let lyrics_id;
    let annotation_id;
  
    try {
      // Upload lyrics if present
      if (song.lyricsFile && !isSaving) {
        const lyricsResponse = await uploadFile(song.lyricsFile);
        lyrics_id = lyricsResponse?.data?.resource[0]?.resource_id;
      }
  
      // Upload annotation if present
      if (song.annotationFile) {
        const annotationResponse = await uploadFile(song.annotationFile);
        annotation_id = annotationResponse?.data?.resource[0]?.resource_id;
      }
  
      // Construct new song object
      const newSong = {
        songName: song.name,
        songUrl: song.mainLink,
        karaokeUrl: song.karaokeLink,
        lyricsId: lyrics_id,
        tags:selectedTags,
        notation_link:song.notation_link,
        annotationId:  annotation_id,
        kidsFriendly: song.kidFriendly,
      };
  
      // Send song to API
      await addSong(newSong);
      toast.success('Song added successfully!');
  
      // Clear the existing songs list before fetching the updated list
      setSongs([]); // Clear current songs
  
      // Fetch updated songs
      await fetchSongs(1);
      setSong({
        name: '',
        mainLink: '',
        karaokeLink: '',
        lyricsFile: null,
        annotationFile: null,
        kidFriendly: false,
        notation_link: '',
      });
      toggleModal();  // Close modal after success
  
    } catch (error) {
     toast.error('Error adding song');
    } finally {
      setIsLoading(false);
      setIsSaving(false);
    }
  };
  const handlePreview = (fileUrl:any) => {
    setPreviewUrl(fileUrl);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setPreviewUrl(null);
  };


  // Upload file utility
  const uploadFile = async (file: File) => {
    try {
      const fileExtension = file.name.split('.').pop();
      let fName = file.name.split(' ').join('_'); // Replace spaces with underscores
      const response = await signedURL({ fileName: fName, fileType: fileExtension });
      if (response.status === 200) {
        let resourseName = response.data.fileName; // Update the resource name to match what the server expects
        const { signedUrl } = response.data;
        const uploadResponse = await uploadResourse(signedUrl, file);
        if (uploadResponse.status === 200) {
          const resourceResponse = await addResource({
            resourceName: resourseName,
            displayName: file.name,
            type: fileExtension,
            description: "",
            url: "",
          });

          if (resourceResponse.status === 200) {
            return resourceResponse;
          }
        }
      }
    } catch (error) {
      console.error('Error during file upload process', error);
    }
    return null;
  };

  // Toggle modal for adding songs
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div>
      {/* Search Bar */}
      <FormGroup style={{ marginTop: '10px' }}>
        <Input
          type="text"
          placeholder="Search songs..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </FormGroup>

      <Button color="primary" onClick={toggleModal}>
        <Plus size={18} /> Add Song
      </Button>

      {isLoading && songs.length === 0 ? (
        <Row className="user-cards-items user-card-wrapper" style={{ marginTop: '15px' }}>
          <Col xs="12" className="text-center">
            <Spinner color="primary" />
            <p>Loading songs...</p>
          </Col>
        </Row>
      ) : (
        <Row className="user-cards-items user-card-wrapper">
          {songs.length > 0 ? (
            songs.map((song, index) => (
              <Col
                key={song.song_id}
                xl="4"
                sm="6"
                xxl="3"
                className="col-ed-4 box-col-4"
              >
                {/* Attach ref to the last song */}
                <div ref={index === songs.length - 1 ? lastSongRef : null}>
                <Card style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px' }}>
  <CardBody style={{ position: 'relative' }}>
    {/* Song Name and Icons Row */}
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {/* Song Name and Music Icon */}
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      
        <h6 style={{ marginLeft: '10px' }}>{song.song_name}</h6>
      </div>

      {/* Song and Karaoke Links */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* YouTube or Music Icon */}
        <a title='Song' href={song.song_url || '#'} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
     
            <Youtube size={22} style={{ color: 'black' }} />
         
        </a>

        {/* Karaoke Icon */}
        {song.karaoke_url && (
          <a title='Karoke' href={song.karaoke_url} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
            <LuMic2 size={22} style={{ color: 'black' }} />
          </a>
        )}

{song.notation_link && (
                  <a title="Notation">
                    <GiMusicalScore
                      size={22}
                      style={{ color: 'black', cursor: 'pointer', marginRight: '10px' }}
                      onClick={() => handlePreview(song.notation_link)}
                    />
                  </a>
                )}

                {/* Lyrics Icon with Preview */}
                {song.lyricsFileUrl && (
                  <a title="Lyrics">
                    <FileText
                      size={22}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePreview(song.lyricsFileUrl)}
                    />
                  </a>
                )}   
</div>
    </div>
  </CardBody>
</Card>

                </div>
              </Col>
            ))
          ) : (
            <p>No songs available.</p>
          )}
        </Row>
      )}
 
   
 
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Add Song</ModalHeader>
      <ModalBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <FormGroup className="mb-3">
          <Label for={`songName`}><b>Enter Song Name</b></Label>
          <Input
            type="text"
            id={`songName`}
            value={song.name}
            onChange={(e) => setSong({ ...song, name: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for={`mainLink`}><b>Main Song Link</b></Label>
          <Input
            type="url"
            id={`mainLink`}
            value={song.mainLink}
            onChange={(e) => setSong({ ...song, mainLink: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for={`karaokeLink`}><b>Karaoke Song Link</b></Label>
          <Input
            type="url"
            id={`karaokeLink`}
            value={song.karaokeLink}
            onChange={(e) => setSong({ ...song, karaokeLink: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for={`uploadLyrics`}><b>Upload Lyrics</b></Label>
          <Input
            type="file"
            accept=".pdf"
            id={`uploadLyrics`}
            onChange={(e) => setSong({ ...song, lyricsFile: e.target.files ? e.target.files[0] : null })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for={`notationLink`}><b>Notation Link</b></Label>
        

<Input
        type="url"
        id="notationLink"
        value={song.notation_link}
        onChange={(e) => setSong({ ...song, notation_link: e.target.value })}
      />
        </FormGroup>
        <Label for={`uploadAnnotation`}><b>Notation File</b></Label>
        <FormGroup>
            <Input
            type="file"
            accept=".pdf"
            id={`uploadAnnotation`}
            onChange={(e) => setSong({ ...song, annotationFile: e.target.files ? e.target.files[0] : null })}

          />
        </FormGroup>
        <Label for={`tags`}><b>Select Tags</b></Label>

         <FormGroup>
    {tags.map((tag, index) => (
          <div key={index}>
            <label>
              <input
              
                type="checkbox"
                value={tag}
                checked={selectedTags.includes(tag)}
                onChange={() => handleCheckboxChange(tag)}
              />
                <span style={{marginLeft:"10px"}}>    {tag}</span>
            </label>
          </div>
        ))}
    </FormGroup>

      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleAddSong}  disabled={isSaving}>    {isSaving ? <Spinner size="sm" /> : 'Save'}</Button>
      </ModalFooter>
    </Modal>
     
   

      {previewUrl && (
        <PreviewModal
          isOpen={isPreviewOpen}
          toggle={closePreview}
          fileUrl={previewUrl}
          title="Lyrics Preview"
        />
      )}
    </div>
  );
}

export default SongsList;
