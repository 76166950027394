import React, { useState } from 'react';
import { Button, Col, Container, Row, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Card, CardBody, CardTitle, Spinner } from 'reactstrap';
import ArtiumCourseManager from './ArtiumCourseManager';
import classnames from 'classnames';
import { getCourses, createCourse } from '../../../../Api/lms/apiUtils';
import ResourcesList from './ResourcesList';
import Search from './search';
import SongsList from './SongList';
import { toast } from 'react-toastify';
import './style.scss';
import TabManager from './tab';
import { H3 } from '../../../../AbstractElements';
import AddLevelModal from './AddLevelModal';



type Course = {
  id: number;
  name: string;
  levels: string[];
  course_name: string[];
  
  lessons: {
    level: string;
    topics: {
      name: string;
      resources: {
        type: string;
        name: string;
        course_name: string[];
      
        levels: string[];
  
      }[];
    }[];
  }[];
};

type NewCourse = {
  id: number;
  name: string;


    level: never[];
    lessons: number;
    

  
  levels: string[];
};


function CourseList() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [selectedLevelDesc, setSelectedLevelDesc] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [isArtiumPageOpen, setIsArtiumPageOpen] = useState(false);
  const [newCourse, setNewCourse] = useState({ name: '', level: [], lessons: 0 });
  const [activeTab, setActiveTab] = useState<any>('Prep');
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showResources, setShowResources] = useState<boolean>(false);
  const [viewingResources, setViewingResources] = useState<boolean>(false);
  const [viewingSongs, setViewingSongs] = useState<boolean>(false);  // New state for viewing resources
  const courseLevels = [
    { id: 1, name: 'Beginner' },
    { id: 2, name: 'Preparatory' },
    { id: 3, name: 'Intermediate' },
    { id: 4, name: 'Intermediate 1' },
    { id: 5, name: 'Intermediate 2' },
    { id: 6, name: 'Intermediate 3' },
    { id: 7, name: 'Advanced' }

  ];
  const fetchCourses = async () => {
    setIsLoading(true);
    try {
      const response = await getCourses();
      if (response.courses) {
        setCourses(response.courses);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const resetForm = () => {
    setNewCourse({ name: '', level: [], lessons: 0 });   // Reset name, level, and lessons
    setSelectedLevels([]);                               // Clear selected levels
    setSelectedLevelDesc([]);                            // Clear level descriptions
  };
  
  

  React.useEffect(() => {
    fetchCourses();
  }, []);

  const toggleNewCourseModal = () => 
    // resetForm();  
    setModalOpen(!modalOpen);

  
  const handleAddCourse = async() => {
    let levelParam = ([] as any);
    // if(selectedLevelDesc.length){ 
    //   levelParam = selectedLevelDesc;
    // }else{
    //   let cloneSelectedLevels = [...selectedLevels];
    //   levelParam = cloneSelectedLevels.map( v => {return { "levelName": v, "description": "" }})
    // }
    courseLevels.forEach((level) => {
      if (selectedLevels.includes(level.name)) {
        levelParam.push({ "levelName": level.name, "description": ((selectedLevelDesc || []).find(v => (v as any).levelName == level.name) as any)?.description || "" });
      }
    }
    );
      debugger;
    let reqParam = {
      "courseName": newCourse.name,
      "description": (newCourse as any).description || "",
      "levels": levelParam
    }
    try {
      const response = await createCourse(reqParam);
      if (response?.course?.course_id) {
        resetForm();   
        toggleNewCourseModal();
        fetchCourses();
       toast.success((response as any).message);
      } else {
        alert("Failed to add course:");
      }
    } catch (error) {
      console.error("Error adding course:", error);
    }
  };

  const handleCourseCardClick = (course: any) => {
    setSelectedCourse(course);
    setIsArtiumPageOpen(true);
    setSelectedLevel(course.levels[0]?.levelName ||'Prep');
    setActiveTab('Prep');
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setSelectedLevel(tab);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    if (checked) {
  
      setSelectedLevels([...selectedLevels, value]);
    } else {

      setSelectedLevels(selectedLevels.filter((level) => level !== value));
    }
  };
  const handleResourcesClick = () => {
    setViewingResources(true);
    setViewingSongs(false); // Make sure only Resources view is active
    setIsArtiumPageOpen(false);
  };
  
  const handleCancel =() =>{
    setModalOpen(!modalOpen);
    resetForm ();
  }
  const handleSongsClick = () => {
    setViewingSongs(true);
    setViewingResources(false); // Make sure only Songs view is active
    setIsArtiumPageOpen(false);
  };
  
  const handleBackToCourseList = () => {
    setIsArtiumPageOpen(false);
    setSelectedLevel('');
    setViewingResources(false);
    setViewingSongs(false); // Reset both resources and songs views
  };
  

  return (
    <div className="page-body">
      <Container>
        {!isArtiumPageOpen && !viewingResources && !viewingSongs ? (
          <>
            <Row className="user-cards-items user-card-wrapper">
              <Col xs="12" className="mb-4">
                <Button color="primary" onClick={toggleNewCourseModal}>Add New Course</Button>
                <Button
                  color="secondary"
                  className={classnames('custom-cancel-button', { 'highlighted-button': viewingResources })}
                  style={{ marginLeft: '15px' }}
                  onClick={handleResourcesClick}
                >
                  Upload Resources
                </Button>

                <Button
                  color="secondary"
                  className={classnames('custom-cancel-button', { 'highlighted-button': viewingSongs })}
                  style={{ marginLeft: '15px' }}
                  onClick={handleSongsClick}
                >
                  Upload Songs
                </Button>

              </Col>
            </Row>

            {isLoading ? (
              <Row className="user-cards-items user-card-wrapper" style={{ marginTop: "15px" }}>
                <Col xs="12" className="text-center">
                  <Spinner color="primary" />
                  <p>Loading courses...</p>
                </Col>
              </Row>
            ) : (
           <div style={{marginTop:"15px"}}>
               <Row className="user-cards-items user-card-wrapper">
              {courses.map((course: any) => (
                <Row key={course.course_id} className="col-ed-4 box-col-4 p-0">
                  <Card onClick={() => handleCourseCardClick(course)} style={{ cursor: 'pointer' }}>
                    <CardBody className="basic-wizard important-validation">
                      <CardTitle tag="h5">{course.course_name}</CardTitle>
                    </CardBody>
                  </Card>
                </Row>
              ))}
            </Row>
           </div>
            
            )}
          </>
        ) : (
          <Row className="user-cards-items user-card-wrapper">
<Col xs="12" className="mb-4 d-flex align-items-center justify-content-between">
  <div className="d-flex align-items-center">
    <Button color="primary" onClick={handleBackToCourseList}>
      Back
    </Button>

    <Button
      className={classnames('custom-cancel-button', { 'highlighted-button': viewingResources })}
      style={{ marginLeft: '15px' }}
      onClick={handleResourcesClick}
    >
      Upload Resources
    </Button>

    <Button
      className={classnames('custom-cancel-button', { 'highlighted-button': viewingSongs })}
      style={{ marginLeft: '15px' }}
      onClick={handleSongsClick}
    >
      Upload Songs
    </Button>
  </div>
  <div>
  {!viewingResources && !viewingSongs && (
    <div
      style={{
        border: '2px solid #e82c86',
        borderRadius: '8px',
        padding: '10px 20px',
        backgroundColor: '#f9f9f9',
        color: '#e82c86',
        fontWeight: 'bold',
      }}
    >
      {selectedCourse?.course_name}
    </div>
  )}
</div>

</Col>



  
            
            {isArtiumPageOpen && selectedLevel && (
  <TabManager 
    selectedCourse={selectedCourse}
    selectedLevel={selectedLevel}
    courseLevels={courseLevels}
    fetchCourses={fetchCourses}
    courseDetail={selectedCourse} // Pass selectedCourse as courseDetail
  />
)}

          </Row>
          
        )}

        {viewingResources && (
          <div className="resources-list-container" style={{ marginTop: '20px' }}>
            <h4>Resources List</h4>
            <ResourcesList />
          </div>
        )}

{viewingSongs && (
          <div className="resources-list-container" style={{ marginTop: '20px' }}>
            <h4>songs List</h4>
        <SongsList/>
          </div>
        )}

{/* {isArtiumPageOpen && selectedLevel && (
        <ArtiumCourseManager selectedCourse={""} courseDetail={selectedCourse} selectedLevel={selectedLevel}   courseLevels={courseLevels} fetchCourses={fetchCourses}  />
        
      )} */}

      <Modal isOpen={modalOpen} toggle={toggleNewCourseModal}>
        <ModalHeader toggle={toggleNewCourseModal}>Add New Course</ModalHeader>
        <ModalBody>
            <FormGroup>
              <Label for="courseName">Course Name</Label>
              <Input
                type="text"
                id="courseName"
                placeholder="Enter course name"
                value={newCourse.name}
                onChange={(e) => setNewCourse({ ...newCourse, name: e.target.value })}
              />
            </FormGroup>
            {/* <FormGroup>
              <Label for="courseDescription">Course Description</Label>
              <Input
                type="textarea"
                id="courseDescription"
                placeholder="Enter course description"
                value={(newCourse as any).description}
                onChange={(e) => setNewCourse(({ ...newCourse, description: e.target.value } as any))}
              />
            </FormGroup> */}
          <FormGroup>
      <Label for="courseLevels">Course Levels</Label>
      <div>
        {courseLevels.map((level, index) => (
          <FormGroup key={index} check>
            <Label check>
              <Input
              type="checkbox"
              value={level.name}
              className='levelChk'
              checked={selectedLevels.some((lvl) => lvl.startsWith(level.name))}
              onChange={handleCheckboxChange}
              />
              {level.name}
            </Label>
            {/* {selectedLevels.some((lvl) => lvl.startsWith(level.name)) && (
              <Input
                type="text"
                placeholder={`Description for ${level.name}`}
                value={(selectedLevelDesc.find((desc:any) => desc.levelName === level.name) as any)?.description || ''}
              onChange={(e) => {
                let cloneSelectedLevelDesc = [...selectedLevelDesc];
                let levelDesc = cloneSelectedLevelDesc.filter((desc:any) => desc.levelName === level.name);
                if (levelDesc.length > 0) {
                  (levelDesc[0] as any)["description"] = e.target.value;
                } else {
                (cloneSelectedLevelDesc as any).push({ levelName: level.name, description: e.target.value });
                }
                setSelectedLevelDesc(cloneSelectedLevelDesc);
                console.log("selectedLevelDesc", selectedLevelDesc);
                console.log("selectedLevels", selectedLevels);
              }}
                // value={selectedLevels.find((lvl) => lvl.startsWith(level.name))?.split(': ')[1] || ''}
                // onChange={(e) => {
                //     // const updatedLevels = selectedLevels.map((lvl) =>
                //     // lvl.startsWith(level.name) ? `${level.name}: ${e.target.value}` : lvl
                //     // );
                //     // setSelectedLevels(updatedLevels);
                //     let cloneSelectedLevelDesc = [...selectedLevelDesc];
                //     let levelDesc = cloneSelectedLevelDesc.filter((desc:any) => desc.level === level.name);
                //     if (levelDesc.length > 0) {
                //       (levelDesc[0] as any)["desc"] = e.target.value;
                //     } else {
                //     (cloneSelectedLevelDesc as any).push({ level: level.name, desc: e.target.value });
                //     }
                //     setSelectedLevelDesc(cloneSelectedLevelDesc);
                //     console.log("selectedLevelDesc", selectedLevelDesc);
                    
                // }}
              />
            )} */}
          </FormGroup>
        ))}
      </div>
    </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddCourse}>Save Course</Button>
          <Button color="secondary" onClick={handleCancel }>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Container>
 

    </div>
    
  );
}

export default CourseList;
