import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Video, Music, FileText, Plus } from 'react-feather';
import { searchResources } from '../../../../Api/lms/apiUtils';
import { _addResource } from '../../../../Api/lms/utility';
import PreviewComponent from './PreviewComponent';
import { toast } from "react-toastify";

interface Resource {
  resource_id: number;
  resource_name: string;
  type: string;
  description: string;
  url: string;
  created_at: string;
  updated_at: string;
  display_name: string;
  fileUrl: string;
}

function ResourcesList() {
  const [resources, setResources] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [previewResource, setPreviewResource] = useState<Resource | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileInputs, setFileInputs] = useState<{ file: File; displayName: string }[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const lastResourceRef = useRef<HTMLDivElement | null>(null);

  // Fetch resources based on search query and pagination
  const fetchResources = async (pageNumber: number) => {
    setIsLoading(true);
    try {
      const response = await searchResources(searchQuery, pageNumber, 20);
      const newResources = response.results || [];
      if (newResources.length === 0) {
        setHasMore(false);
      } else {
        setResources((prevResources) => [...prevResources, ...newResources]);
      }
    } catch (error) {
      console.error('Error fetching resources:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial fetch and when search query changes
  useEffect(() => {
    setResources([]);
    setPage(1);
    setHasMore(true);
    fetchResources(1);
  }, [searchQuery]);

  // Fetch more resources on page increment
  useEffect(() => {
    if (page > 1) {
      fetchResources(page);
    }
  }, [page]);

  const handlePreviewToggle = (resource: Resource | null) => {
    setPreviewResource(resource);
    setIsPreviewOpen(!isPreviewOpen);
  };


  // Handle scrolling for infinite scroll functionality
  const handleScroll = () => {
    if (
      lastResourceRef.current &&
      lastResourceRef.current.getBoundingClientRect().bottom <= window.innerHeight + 100 &&
      !isLoading &&
      hasMore
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, isLoading]);

  // Handle file input changes
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const inputs = selectedFiles.map(file => ({
        file,
        displayName: file.name.split('.')[0] // Initial display name
      }));
      setFileInputs(inputs);
    }
  };

  // Handle display name changes for each file
  const handleDisplayNameChange = (index: number, newDisplayName: string) => {
    setFileInputs(prevInputs => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].displayName = newDisplayName;
      return updatedInputs;
    });
  };

  // Save files by making an API call for each file
  const handleSave = async () => {
    if (fileInputs.length > 0 && !isSaving) {
      setIsSaving(true);
      setIsLoading(true);
      try {
        for (let i = 0; i < fileInputs.length; i++) {
          const { file, displayName } = fileInputs[i];
          let resourceRes = await _addResource([file], displayName);
          if (resourceRes) {
            toast.success(`Resource "${file.name}" added successfully`);
          }
        }
        // Reset states after saving
        setFileInputs([]);
        setResources([]);
        setPage(1);
        fetchResources(1);
      } catch (error) {
        console.error('Error adding resources:', error);
        toast.error('Error adding resources');
      } finally {
        setIsSaving(false);
        setIsLoading(false);
        setIsModalOpen(false);
      }
    }
  };

  // Toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) { // When closing the modal
      setFileInputs([]); // Clear file input state
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Clear file input field
      }
    }
  };
  

  return (
    <div>
      <FormGroup style={{ marginTop: '10px' }}>
        <Input
          type="text"
          placeholder="Search resources..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </FormGroup>

      <Button color="primary" onClick={toggleModal}>
        <Plus size={18} /> Add Resource
      </Button>

      {isLoading && resources.length === 0 ? (
        <Row className="user-cards-items user-card-wrapper" style={{ marginTop: '15px' }}>
          <Col xs="12" className="text-center">
            <Spinner color="primary" />
            <p>Loading resources...</p>
          </Col>
        </Row>
      ) : (
        <Row className="user-cards-items user-card-wrapper">
          {resources.length > 0 ? (
            resources.map((resource, index) => (
              <Col
                key={resource.resource_id}
                xl="4"
                sm="6"
                xxl="3"
                className="col-ed-4 box-col-4"
              >
                <div ref={index === resources.length - 1 ? lastResourceRef : null}>
                <Card
                    style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px', position: 'relative' }}
                    onClick={() => handlePreviewToggle(resource)}
                 >
                    <CardBody style={{ position: 'relative' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '-14px' }}>
                          {resource.type === 'video' && <Video color="grey" />}
                          {resource.type === 'audio' && <Music color="grey" />}
                          {resource.type === 'pdf' && <FileText color="grey" />}
                          {resource.type === 'mp3' && <Music color="grey" />}
                          {resource.type === 'mp4' && <Video color="grey" />}

                          {resource.type === 'mov' && <Video color="grey" />}
                          
                          
                          <h6 style={{ marginLeft: '10px' }}>
                            {resource.display_name ? resource.display_name.split('.')[0] : resource.resource_name}
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            ))
          ) : (
            <Col xs="12" className="text-center">
              <p>No resources found</p>
            </Col>
          )}
        </Row>
      )}

      {isLoading && resources.length > 0 && (
        <Row className="user-cards-items user-card-wrapper" style={{ marginTop: '15px' }}>
          <Col xs="12" className="text-center">
            <Spinner color="primary" />
          </Col>
        </Row>
      )}

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Resource</ModalHeader>
        <ModalBody>
        <FormGroup>
        <input
    type="file"
    accept=".mp4,.mp3,.pdf,.doc,.docx,.avi,.mov,.wmv,.flv,.mkv"
    multiple
    onChange={handleFileChange}
    ref={fileInputRef} // Attach ref here
    className="form-control" // Optional: for consistent styling
    id="fileUpload" // Optional: for label association
  />
  {fileInputs.map((input, index) => (
    <div key={index} style={{ marginTop: '10px' }}>
      <p>{input.file.name}</p>
      <Input
        type="text"
        value={input.displayName}
        onChange={(e) => handleDisplayNameChange(index, e.target.value)}
        placeholder="Display Name"
      />
    </div>
  ))}
</FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? <Spinner size="sm" /> : 'Save'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal }>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {previewResource && (
        <PreviewComponent
          resource={previewResource}
          isOpen={isPreviewOpen}
          onClose={() => setPreviewResource(null)}
        />
      )}
    </div>
  );
}

export default ResourcesList;
