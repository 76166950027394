// SonglistCard.tsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Card, CardBody, CardHeader, Button, Input, FormGroup, Label, Row, Col, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, Spinner
} from 'reactstrap';
import { Trash,  Plus, Music, Youtube, FileText, Video } from 'react-feather';
import './style.scss';
import { signedURL, uploadResourse, addResource, addSong, getSongLevel, addSongWithLevel, removeSongFromLevel, editSong, getTags } from '../../../../Api/lms/apiUtils';
import SongSearchComponent from './searchSong';
import { toBeRequired } from '@testing-library/jest-dom/matchers';
import PreviewModal from './songpreview';
import { toast } from "react-toastify";
import { LuMic2 } from "react-icons/lu";
import { GiMusicalScore } from "react-icons/gi";
import { CiEdit } from "react-icons/ci";



interface Song {
  name: string;
  mainLink: string;
  karaokeLink: string;
  lyricsFile: File | null;
  annotationFile: any | null; 
  kidFriendly: boolean;
  notation_link: any;
  
  
}

interface FetchedSong {
  song_id: number;
  song_name: any;
  tags:any;
  song_url: any;
  karaoke_url: any;
  annotationFileUrl: any | null;
  lyrics_id: number;
  annotation_id: number;
  kids_friendly: boolean;
  lyricsFile: File | null;
  annotationFile: any | null;
 lyricsFileUrl:any|null;
 notation_link: any ;

}


interface SonglistCardProps {
  toggle: () => void;
  levelId: number;
}

const SonglistCard: React.FC<SonglistCardProps> = ({ toggle, levelId, }) => {
  const [songs, setSongs] = useState<Song[]>([
    { name: '', mainLink: '', karaokeLink: '',  notation_link :'', lyricsFile: null, annotationFile:null, kidFriendly: false, }
  ]);
  const [song, setSong] = useState<Song>({ name: '', mainLink: '', karaokeLink: '', notation_link :'',lyricsFile: null, annotationFile:null, kidFriendly: false,  });
  const [songList, setSongList] = useState<FetchedSong[]>([]);
  const [isOpen, setIsOpen] = useState(false); 
  const [isSongListOpen, setIsSongListOpen] = useState(false); 
  const [addIsOpen, setAddIsOpen] = useState(false); 
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
const [songToDelete, setSongToDelete] = useState<FetchedSong | null>(null);
const [isPreviewOpen, setIsPreviewOpen] = useState(false); // State to control preview modal
const [previewUrl, setPreviewUrl] = useState<string | null>(null); 
const [isUploading, setIsUploading] = useState(false); 
const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const [songToEdit, setSongToEdit] = useState<FetchedSong | null>(null);
const [isChecked, setIsChecked] = useState(false); // Checkbox state
const [tags, setTags] = useState([]); // State to store fetched tags
const [selectedTags, setSelectedTags] = useState<any[]>([]); // Use string[] instead of number[]



  const fetchSongs = async () => {
    try {
      const response = await getSongLevel(levelId);
      if (response) {
        setSongList(response.songs);
      } else {
        console.log('Error fetching songs', response);
      }
    } catch (error) {
      console.error('Error fetching songs', error);
    }
  };
  
  useEffect(() => {
    if (songToEdit) {
      setSelectedTags(songToEdit.tags || []);  // Set the initial selected tags when the modal is opened
    }
  }, [songToEdit]);
  

  useEffect(() => {
    fetchSongs();
  }, []);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await getTags(); // No need to pass data for GET
        if (response && response.data) {
          setTags(response.data.tags);
          console.log("Tags fetched:", response.data.tags);
        } else {
          setTags([]);
        }
      } catch (err) {
        console.error('Error fetching tags:', err);
      }
    };
  
    fetchTags();
  }, []);
  
  
  const handleTagChange = (tagId: number) => {
  setSelectedTags((prevSelectedTags) =>
    prevSelectedTags.includes(tagId)
      ? prevSelectedTags.filter((id) => id !== tagId) // Remove if already selected
      : [...prevSelectedTags, tagId] // Add if not selected
  );
};

const handleEditSong = async () => {
  let lyrics_id;
  let annotation_id;
  if (!songToEdit) return;

  try {
    let lyricsId = songToEdit.lyrics_id;
    let annotationId = songToEdit.annotation_id;

    // Handle file uploads for lyrics and annotations
    if (songToEdit.lyricsFile) {
      const lyricsResponse = await uploadSongFile(songToEdit.lyricsFile);
      lyricsId = lyricsResponse?.data?.resource[0]?.resource_id || lyricsId;
    }

    if (songToEdit.annotationFile) {
      const annotationResponse = await uploadSongFile(songToEdit.annotationFile);
      annotationId = annotationResponse?.data?.resource[0]?.resource_id || annotationId;
    }

    // Include selectedTags in the payload
    const body = {
      songName: songToEdit.song_name,
      songUrl: songToEdit.song_url,
      karaokeUrl: songToEdit.karaoke_url,
      lyricsId,
      annotationId,
      notation_link:songToEdit.notation_link,
      kidsFriendly: songToEdit.kids_friendly,
      tags: selectedTags,  // Use selectedTags instead of songToEdit.tags
    };

    const response = await editSong(songToEdit.song_id, body);

    if (response.status === 200) {
      fetchSongs();
      toast.success('Song updated successfully');
      closeEditModal();
    } else {
      toast.error('Failed to update song');
    }
  } catch (error) {
    console.error('Error updating song', error);
    toast.error('Error updating song');
  }
};

  
  const handleInputChange = (field: keyof FetchedSong, value: any) => {
    if (songToEdit) {
      setSongToEdit({ ...songToEdit, [field]: value });
    }
  };

  const openEditModal = (song: FetchedSong) => {
    setSongToEdit({ ...song });
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSongToEdit(null);
  };

  const handleSongChange = (index: number, field: keyof Song, value: any) => {
    let updatedSong = { ...song };
    updatedSong = { ...updatedSong, [field]: value };
    setSong(updatedSong as any);
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

const clearFileInput = () => {
  if (fileInputRef.current) {
    fileInputRef.current.value = ''; // This resets the input field.
  }
};

  const _addSong = async (newSong: any) => {
    setIsUploading(true);
    try {
      const response = await addSong(newSong);
      if (response.status === 201) {
        const songResponse = await addSongWithLevel({
          "songs": [response.data.songId],
          "levelId": levelId
        });
        if (songResponse.status === 200) {
          setSong({ name: '', mainLink: '', karaokeLink: '', notation_link :'',lyricsFile: null, annotationFile:null, kidFriendly: false });
          fetchSongs();
          clearFileInput();
          setSelectedTags([]);
          setIsUploading(false);
      
          
        toast.success('Song added successfully');
        } else {
          console.log('Error adding song to level', songResponse);
        }       
      } else {
        console.log('Error adding song', response);
      }
    } catch (error) {
      console.error('Error adding song', error);
    }
  };

  const openDeleteModal = (song: FetchedSong) => {
    setSongToDelete(song);
    setIsDeleteModalOpen(true);
  };
  
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSongToDelete(null);
  };
  
  const handleDeleteSong = async () => {
    if (!songToDelete) return;
    try {
      const response = await removeSongFromLevel(songToDelete.song_id, levelId);
    
      if (response.status === 200) {
        fetchSongs(); // Refresh the song list after deletion
        toast.success('Song removed successfully');
      } else {
        console.log('Error removing song from level', response);
        toast.error('Error removing song from level');
      }
    } catch (error) {
      console.error('Error removing song from level', error);
      toast.error('Error removing song from level');
      console.log("songId", songToDelete.song_id, levelId)
    } finally {
      closeDeleteModal(); // Close the modal after processing
    }
  };
  
  

  const handleAddExistingSongs = async (songsToAdd: FetchedSong[]) => {
    try {
      // Filter out songs that are already in the songList by checking their song_id
      const newSongsToAdd = songsToAdd.filter(song => 
        !songList.some(existingSong => existingSong.song_id === song.song_id)
      );
  
      if (newSongsToAdd.length === 0) {
        // Show a toast message or notification if all songs are already in the list
        toast.error('All selected songs are already in the list');
        return;
      }
  
      const songIds = newSongsToAdd.map(song => song.song_id);
      const response = await addSongWithLevel({
        songs: songIds,
        levelId: levelId,
      });
  
      if (response.status === 200) {
        fetchSongs(); // Refresh the song list
        toast.success('New songs added successfully');
      } else {
        console.log('Error adding existing songs to level', response);
      }
    } catch (error) {
      console.error('Error adding existing songs to level', error);
    }
  };
  const filteredSongs = songList.filter(song => 
    song.song_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  const uploadSongFile = async (file: File) => {
    try {
      if (file) {
        const fileExtension = file.name.split('.').pop();
        let fName = file.name.split(' ').join('_'); // Replace spaces with underscores
        const response = await signedURL({ fileName: fName, fileType: fileExtension });
        if (response.status === 200) {
          let resourseName = response.data.fileName; // Update the resource name to match what the server expects
          const { signedUrl } = response.data;
          const uploadResponse = await uploadResourse(signedUrl, file);
          if (uploadResponse.status === 200) {
            const resourceResponse = await addResource({
              resourceName: resourseName,
              displayName: file.name,
              type: fileExtension,
              description: "", // Add your resource description here
              url: "", // Optional URL
            });

            if (resourceResponse.status === 200) {
              return resourceResponse;
            } else {
              console.log('Error adding resource', resourceResponse);
              return null;
            }
          } else {
            console.log('Error uploading file', uploadResponse);
            return null;
          }
        }
      }
    } catch (error) {
      console.error('Error during file upload process', error);
    }
  };

  const handlePreview = (fileUrl:any) => {
    setPreviewUrl(fileUrl);
    setIsPreviewOpen(true);
  };
  const closePreview = () => {
    setIsPreviewOpen(false);
    setPreviewUrl(null);
  };

  const handleAddSong = async () => {
    let lyrics_id;
    let annotation_id;
  
    try {
      // Upload lyrics file
      if (song.lyricsFile) {
        const lyricsResponse = await uploadSongFile(song.lyricsFile);
        lyrics_id = lyricsResponse?.data?.resource[0]?.resource_id;
        console.log("Lyrics ID:", lyrics_id); // Debugging
      }
  
      // Upload annotation file
      if (song.annotationFile) {
        const annotationResponse = await uploadSongFile(song.annotationFile);
        annotation_id = annotationResponse?.data?.resource[0]?.resource_id;
        console.log("Annotation ID:", annotation_id); // Debugging
      }
  
      // Construct song object
      const newSong = {
        songName: song.name,
        songUrl: song.mainLink,
        karaokeUrl: song.karaokeLink,
        lyricsId: lyrics_id,
        tags:selectedTags,
        notation_link:song.notation_link,
        annotationId:  annotation_id,
        kidsFriendly: song.kidFriendly,
      };
  
      // Debug newSong payload
      console.log("New Song Payload:", newSong);
  
      // Add song to database
      await _addSong(newSong);
      console.log("Song successfully added!");
    } catch (error) {
      console.error("Error adding song:", error);
    }
  };
  

  const handleRemoveSong = (index: number) => {
    const updatedSongs = songs.filter((_, i) => i !== index);
    setSongs(updatedSongs);
  };
  const handleCheckboxChange = (tag: string) => {
    setSelectedTags((prev) =>
      prev.includes(tag)
        ? prev.filter((t) => t !== tag)  // Remove tag if already selected
        : [...prev, tag]  // Add tag if not selected
    );
  };
  

  const handleFileChange = (file: File | null, key: string) => {
    if (songToEdit) {
      setSongToEdit({ ...songToEdit, [key]: file });
    }
  };
  

  const toggleCollapse = () => setIsOpen(!isOpen);
  const toggleSongListCollapse = () => setIsSongListOpen(!isSongListOpen);
  const toggleAddCollapse = () => setAddIsOpen(!addIsOpen);

  return (
<Card className="mb-3">
  <CardHeader onClick={toggleSongListCollapse} style={{ cursor: 'pointer' }}>
    <strong>Song List</strong>
    <Button
      color="link"
      onClick={toggleSongListCollapse}
      style={{ float: 'right', padding: '0', textDecoration: 'none' }}
    >
      {isSongListOpen ? '▼' : '►'}
    </Button>
  </CardHeader>
  <Collapse isOpen={isSongListOpen}>
    <CardBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <Input
          type="text"
          placeholder="Search by title..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: "250px" }}
        />
      </div>

      {filteredSongs.length > 0 ? (
        <ul style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px' }}>
          {filteredSongs.map((song,index) => (
            <Card key={song.song_id}>
              <Row className="d-flex align-items-center justify-content-between">
                {/* Title on the left */}
                <Col md="9">
                  <strong>Title:</strong> {song.song_name}
                </Col>
                <Col md="9">
  <strong>Tags:</strong>
  {Array.isArray(song.tags) && song.tags.length > 1 ? (
    song.tags.map((tag, index) => (
      <div key={index}>
        {index + 1}. {tag}
      </div>
    ))
  ) : (
    <span>{song.tags}</span>  // If there is only one tag, show it as it is
  )}
</Col>


                {/* Icons on the right */}
                <Col md="3" className="d-flex justify-content-end align-items-center gap-2">
                  <a title='song' href={song.song_url || '#'} target="_blank" rel="noopener noreferrer">
                    <Youtube size={22} style={{ color: 'black' }} />
                  </a>
                  {song.karaoke_url && (
                    <a title='Karaoke' href={song.karaoke_url} target="_blank" rel="noopener noreferrer">
                      <LuMic2 size={22} style={{ color: 'black' }} />
                    </a>
                  )}
                  {song.notation_link && (
                    <a title="Notation">
                      <GiMusicalScore
                        size={22}
                        style={{ color: 'black', cursor: 'pointer' }}
                        onClick={() => handlePreview(song.notation_link)}
                      />
                    </a>
                  )}
                  {song.lyricsFileUrl && (
                    <a title="Lyrics">
                      <FileText
                        size={22}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => handlePreview(song.lyricsFileUrl)}
                      />
                    </a>
                  )}

                  <Button onClick={() => openDeleteModal(song)}>
                    <Trash size={18} />
                  </Button>
                  <Button color='link' onClick={() => openEditModal(song)}>
                    <CiEdit size={18} />
                  </Button>
                </Col>
              </Row>
            </Card>
          ))}
        </ul>
      ) : (
        <p>No songs added yet.</p>
      )}
    </CardBody>
  </Collapse>
  {songToEdit && (
    <Modal isOpen={isEditModalOpen} toggle={closeEditModal}>
  <ModalHeader toggle={closeEditModal}>Edit Song</ModalHeader>
  <ModalBody>
    <FormGroup>
      <Label for="songName">Song Name</Label>
      <Input
        type="text"
        id="songName"
        value={songToEdit?.song_name || ''}
        onChange={(e) => handleInputChange('song_name', e.target.value)}
      />
    </FormGroup>
    <FormGroup>
      <Label for="songUrl">Main Song URL</Label>
      <Input
        type="url"
        id="songUrl"
        value={songToEdit?.song_url || ''}
        onChange={(e) => handleInputChange('song_url', e.target.value)}
      />
    </FormGroup>
    <FormGroup>
      <Label for="karaokeUrl">Karaoke URL</Label>
      <Input
        type="url"
        id="karaokeUrl"
        value={songToEdit?.karaoke_url || ''}
        onChange={(e) => handleInputChange('karaoke_url', e.target.value)}
      />
    </FormGroup>
    <FormGroup>
      <Label for="lyricsFile'">Upload Lyrics</Label>
      <input
        type="file"
        id="lyricsFile"
        className="form-control"
        accept=".pdf"
        onChange={(e) => handleFileChange(e.target.files ? e.target.files[0] : null, 'lyricsFile')}
      />
    </FormGroup>
    <FormGroup>
      <Label for="notationLink">Notation Link</Label>
      <Input
        type="url"
        id="notationLink"
        value={songToEdit?.notation_link|| ''}
    
        onChange={(e) => handleInputChange('notation_link', e.target.value)}
      />
    </FormGroup>
   <FormGroup>
    {tags.map((tag, index) => (
          <div key={index}>
            <label>
              <input
                type="checkbox"
                value={tag}
             
                checked={selectedTags.includes(tag)}
                onChange={() => handleCheckboxChange(tag)}
              />
              {tag}
            </label>
          </div>
        ))}
    </FormGroup>
  </ModalBody>
  <ModalFooter>
    <Button color="primary" onClick={handleEditSong}>
      Save Changes
    </Button>
    <Button color="secondary" onClick={closeEditModal}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>

)}


  <CardHeader onClick={toggleAddCollapse} style={{ cursor: 'pointer' }}>
    <strong>Add Existing Song</strong>
    <Button
      color="link"
      onClick={toggleAddCollapse}
      style={{ float: 'right', padding: '0', textDecoration: 'none' }}
    >
      {addIsOpen ? '▼' : '►'}
    </Button>
  </CardHeader>
  <Collapse isOpen={addIsOpen}>
    <SongSearchComponent setSongs={handleAddExistingSongs} />
  </Collapse>

  <CardHeader onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
    <strong>Add New Song</strong>
    <Button
      color="link"
      onClick={toggleCollapse}
      style={{ float: 'right', padding: '0', textDecoration: 'none' }}
    >
      {isOpen ? '▼' : '►'}
    </Button>
  </CardHeader>
  <Collapse isOpen={isOpen}>
    <CardBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
      <FormGroup className="mb-3">
        <Label for={`songName`}>Enter Song Name <span style={{ color: 'red' }}>*</span></Label>
        <Input
          type="text"
          id={`songName`}
          required
          value={song.name}
          onChange={(e) => handleSongChange(0, 'name', e.target.value)}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Label for={`mainLink`}>Main Song Link <span style={{ color: 'red' }}>*</span></Label>
        <Input
          type="url"
          id={`mainLink`}
          required
          value={song.mainLink}
          onChange={(e) => handleSongChange(0, 'mainLink', e.target.value)}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Label for={`karaokeLink`}>Karaoke Song Link</Label>
        <Input
          type="url"
          id={`karaokeLink`}
          value={song.karaokeLink}
          onChange={(e) => handleSongChange(0, 'karaokeLink', e.target.value)}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Label for={`lyricsFile`}>Upload Lyrics</Label>
        <input
          className="form-control"
          ref={fileInputRef}
          type="file"
          accept=".pdf"
          id={`lyricsFile`}
          onChange={(e) => setSong({ ...song, lyricsFile: e.target.files ? e.target.files[0] : null })}
          />
      </FormGroup>
      <FormGroup className="mb-3">
  <Label for={`uploadAnnotation`}>Notation Link</Label>
  <Input
  
    type="url"
    id={`uploadAnnotation`}
    value={song.notation_link} // Safe handling for null values

    onChange={(e) => handleSongChange(0, 'notation_link', e.target.value)}
  />
</FormGroup>

<FormGroup className="mb-3">
  <Label for={`annotationFile`}>Notation File</Label>
  <Input
     className="form-control"
     accept=".pdf"
       type="file"
    id={`annotationFile`}

    // onChange={(e) => handleSongChange(0, 'notation_link', e.target.value)}
    onChange={(e) => setSong({ ...song, annotationFile: e.target.files ? e.target.files[0] : null })}

  />
</FormGroup>


    <div>
    <strong>Select Tags:</strong>
    <FormGroup>
    {tags.map((tag, index) => (
          <div key={index}>
            <label>
              <input
                ref={fileInputRef}
                type="checkbox"
                value={tag}
                checked={selectedTags.includes(tag)}
                onChange={() => handleCheckboxChange(tag)}
              />
              {tag}
            </label>
          </div>
        ))}
    </FormGroup>
  </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <Button
          disabled={isUploading}
          color="primary"
          onClick={handleAddSong}
          className="mb-3"
        >
          {isUploading ? (
            <Spinner size="sm" /> // Show spinner while uploading
          ) : ('Save')}
        </Button>
      </div>
    </CardBody>
  </Collapse>

  {/* Cancel Button */}
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
    <Button className="custom-cancel-button" style={{ marginTop: '20px' }} onClick={toggle}>
      Cancel
    </Button>
  </div>
  {previewUrl && (
    <PreviewModal
      isOpen={isPreviewOpen}
      toggle={closePreview}
      fileUrl={previewUrl}
      title="Lyrics Preview"
    />
  )}
</Card>

  )};

export default SonglistCard;
