import React, { useState, useEffect, useRef } from 'react';
import RubricsAssignment, { RubricsAssignmentRef } from './rubricsAssignment';
import {
  Button,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { addAssignment, editAssignment, deleteResourseFromAssignment , getRubrics, getLessonRubrics, rubricsToAssignment, getAssignmentRubrics} from '../../../../Api/lms/apiUtils';
import { Trash } from 'react-feather';
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import { CiEdit } from "react-icons/ci";

interface Resource {
  resource_id: any;
  resource_name: any;
  type: string;
  description: string;
  url: string;
  resource:any;
}

interface Rubric {
  rubric_id: number;
  rubric_name: string;
}

interface Assignment {
  name: string;
  description: string;
  resources: Resource[];
  assignment_id :any;


  
}

interface AddAssignmentCardProps {
  topicId: number;
  onSave: (assignment: Assignment) => void;
  onCancel: () => void;
  existingResources: Resource[];
  topic: any;
  deleteAssignment: any;
  openDeleteModal: any;
  courseId: number;
  lessonId:number;

  
}

const AddAssignmentCard: React.FC<AddAssignmentCardProps> = ({
  lessonId,
  courseId,
  topicId,
  onSave,
  onCancel,
  existingResources,
  topic,
  openDeleteModal,

}) => {
  const [assignmentName, setAssignmentName] = useState<string>('');
  const [assignmentInstruction, setAssignmentInstruction] = useState<any>('');
  const [selectedResources, setSelectedResources] = useState<Resource[]>([]);
  const [submittedAssignments, setSubmittedAssignments] = useState<Assignment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingAssignment, setEditingAssignment] = useState<Assignment | null>(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [resourceToDelete, setResourceToDelete] = useState<Resource | null>(null);
  const [isRubricsModalOpen, setRubricsModalOpen] = useState(false); // Rubrics modal state
  const [rubrics, setRubrics] = useState<Rubric[]>([]); // Store fetched rubrics
  const [assignRubrics, setAssignRubrics] = useState<Rubric[]>([]); // Store fetched rubrics
  const [selectedRubrics, setSelectedRubrics] = useState<number[]>([]); 
  const [rubricToDelete, setRubricToDelete] = useState<Rubric | null>(null);
  const [rubricsRefreshTrigger, setRubricsRefreshTrigger] = useState(0);

  const rubricsAssignmentRef = useRef<RubricsAssignmentRef>(null);
  const toggleModal = () => {
    console.log("topic", topic);
    if (!isModalOpen) {
      // This ensures fields are reset only when the modal is opening and in Add mode
      if (!isEditing) {

      }
    }
    setIsModalOpen(!isModalOpen);
  };


  const toggleDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };
  const toggleRubricsModal = () => setRubricsModalOpen(!isRubricsModalOpen);

  const fetchAllRubrics = async (lessonId: number) => {
    try {
      const response = await getLessonRubrics(lessonId); // Pass courseId dynamically
      if (response.rubrics) {
        setRubrics(response.rubrics);
      } else {
        toast.error("Failed to fetch rubrics.");
      }
    } catch (error) {
      console.error("Error fetching rubrics:", error);
   
    }
  };
  

  
  const handleDeleteResource = async (assignmentId: number, resourceId: number) => {
    try {
      const response = await deleteResourseFromAssignment(assignmentId, resourceId);
      if (response.status === 200) {
        toast.success('Resource deleted successfully');
  
        // Remove the resource from selectedResources
        setSelectedResources((prevResources) =>
          prevResources.filter((resource) => resource.resource_id !== resourceId)
        );
  
        // Optionally, update the submitted assignments list
        setSubmittedAssignments((prevAssignments) =>
          prevAssignments.map((assignment) =>
            assignment.assignment_id === assignmentId
              ? {
                  ...assignment,
                  resources: assignment.resources.filter(
                    (resource) => resource.resource_id !== resourceId
                  ),
                }
              : assignment
          )
        );
      } else {
        throw new Error('Failed to delete resource');
      }
    } catch (error) {
      console.error('Error deleting resource:', error);
      toast.error('Failed to delete resource. Please try again.');
    }
  };
  
  const fetchRubrics = async ( assignmentId:any) => {
    try {
      const response = await getAssignmentRubrics(assignmentId);
      if (response?.rubrics && Array.isArray(response.rubrics)) {
        setRubrics(response.rubrics);
      } else {
        toast.error('Failed to fetch rubrics for the assignment.');
      }
    } catch (error) {
      console.error('Error fetching rubrics:', error);
  
    }
  };


  const confirmDelete = () => {
    if (resourceToDelete) {
      handleDeleteResource(editingAssignment?.assignment_id, resourceToDelete.resource_id);
      setDeleteModalOpen(false);
      setResourceToDelete(null);
    }
  };
  const handleTrashClick = (resource:any) => {
    setResourceToDelete(resource);
    toggleDeleteModal();
  };
  
  const resetFormFields = () => {
    setAssignmentName('');
    setAssignmentInstruction('');
    setSelectedResources([]);
    // Optionally clear editing assignment context
    // setEditingAssignment(null);
  };

  const handleAddAssignment = async () => {
    if (assignmentName.trim() === '') {
      toast.error('Assignment name cannot be empty');
      return;
    }

    if (selectedResources.length === 0) {
      alert('Please select at least one resource for the assignment.');
      return;
    }

    setIsLoading(true);

    const newAssignment: Assignment = {
      name: assignmentName,
      description: assignmentInstruction,
      resources: selectedResources,
      assignment_id: null,
    };

    const requestBody = {
      topicId,
      name: newAssignment.name,
      description: newAssignment.description,
      resources: newAssignment.resources.map((resource: any) => resource.resource_id),
    };

    try {
      if (isEditing && editingAssignment) {
        // Call the editAssignment API
        const response = await editAssignment(editingAssignment.assignment_id , requestBody);
        if (response.status === 200) {
          toast.success('Assignment edited successfully');

          setSubmittedAssignments((prevAssignments) =>
            prevAssignments.map((assignment) =>
              assignment === editingAssignment ? newAssignment : assignment
            )
          );
          
          onSave(newAssignment);
        } else {
          throw new Error('Failed to edit assignment');
        }
      } else {
        // Add new assignment
        const response = await addAssignment(requestBody);
        if (response.status === 201) {
          toast.success('Assignment added successfully');
          setSubmittedAssignments((prevAssignments) => [...prevAssignments, newAssignment]);

        } else {
          throw new Error('Failed to add assignment');
        }
      }

      // Reset form and close modal
      setAssignmentName('');
      setAssignmentInstruction('');
      setSelectedResources([]);
      setIsEditing(false);
      setEditingAssignment(null);
      onSave(newAssignment);
      toggleModal();
    } catch (error) {
      console.error('Error saving assignment:', error);
      alert('There was an error saving the assignment. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };


  
  const handleRubricSelection = (rubricId: number) => {
    if (selectedRubrics.includes(rubricId)) {
      setSelectedRubrics(selectedRubrics.filter((id) => id !== rubricId));
    } else {
      setSelectedRubrics([...selectedRubrics, rubricId]);
    }
  };
  const handleAddRubricsToAssignment = async () => {
    try {
      for (const rubricId of selectedRubrics) {
        const payload = {
          rubricId,
          assignmentId: editingAssignment?.assignment_id,
          lessonId: lessonId,
        };
        await rubricsToAssignment(payload);
      }
  
      toast.success('Rubrics added successfully!');
  setSelectedRubrics([]);
  
      // Trigger re-fetch in RubricsAssignment
      setRubricsRefreshTrigger((prev) => prev + 1); // Increment trigger state
  
      toggleRubricsModal();
    } catch (error) {
      console.error('Error adding rubrics to assignment:', error);
      toast.error('Failed to add rubrics. Please try again.');
    }
  };
  
  const handleResourceSelection = (resource: Resource) => {
    if (selectedResources.includes(resource)) {
      setSelectedResources(selectedResources.filter((res) => res !== resource));
    } else {
      setSelectedResources([...selectedResources, resource]);
    }
  };
  const handleEditAssignment = (assignment: Assignment) => {
    setEditingAssignment(assignment); // Set the current assignment for editing
    setAssignmentName(assignment.name); // Populate assignment name
    setAssignmentInstruction(assignment.description); // Populate instructions
    setSelectedResources(assignment.resources); // Populate resources
    setIsEditing(true); // Enable Edit mode
    toggleModal(); // Open the modal
    
  };
  
  

  return (
    <div style={{ marginTop: '10px' }}>
      <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <ListGroup>
          {topic.assignments.map((assignment: any, index: number) => (
            <ListGroupItem key={index}>
<Card>
  <CardBody style={{ position: "relative", padding: "10px" }}>
    {/* Action buttons at the top-right corner */}
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        display: "flex",
        gap: "10px", // Space between buttons
      }}
    >
      <Button
        onClick={() => openDeleteModal(topicId, assignment.assignment_id)}
        style={{ padding: "5px", border: "none", background: "transparent" }}
      >
        <Trash size={18} />
      </Button>
      <Button
        color="link"
        onClick={() => handleEditAssignment(assignment)} // Pre-fill modal for editing
        style={{ padding: "5px", border: "none", background: "transparent" }}
      >
        <CiEdit size={22} />
      </Button>
    </div>

    {/* Main Content */}
    <div style={{ padding: "10px", lineHeight: "1.8" }}>
      <div style={{ marginBottom: "10px" }}>
        <strong>Name:</strong> <span>{assignment.name}</span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <strong>Description:</strong>
        <span
          style={{ marginLeft: "5px" }}
          dangerouslySetInnerHTML={{ __html: assignment.description }}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <strong>Resources:</strong>
        <span style={{ marginLeft: "5px" }}>
          {assignment.resources.map((resource: any, i: number) => (
            <span key={i} style={{ marginRight: "10px" }}>
              {resource.resource_name}
            </span>
          ))}
        </span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        {/* <strong>Rubrics:</strong> */}
        <span style={{ marginLeft: "5px" }}>
        <RubricsAssignment
          key={index}
          ref={rubricsAssignmentRef}
          assignmentId={assignment.assignment_id}
          lessonId={lessonId}
          refresh={rubricsRefreshTrigger} // Pass refresh trigger
        />
  
        </span>
      </div>
    </div>

    <Button
      color="primary"
      style={{
        width: "120px", 
        height: "40px", 
        padding: "5px 10px", 
        fontSize: "14px", 
      }}
      onClick={() => {
        setEditingAssignment(assignment);
        fetchAllRubrics(lessonId);
        toggleRubricsModal();
      }}
    >
      Add Rubrics
    </Button>
  </CardBody>
</Card>


            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
      <Button
  color="primary"
  onClick={() => {
    setIsEditing(false); 
    toggleModal(); 
    resetFormFields();
  }}
  style={{ marginTop: '10px' }}
>
  Add Assignment
</Button>


<Modal isOpen={isModalOpen} toggle={toggleModal}>
  <ModalHeader toggle={toggleModal}>
    {isEditing ? 'Edit Assignment' : 'Add Assignment'}
  </ModalHeader>
  <ModalBody>
    <FormGroup>
      <Label for="assignmentName">Assignment Name</Label>
      <span style={{ color: 'red' }}>*</span>
      <Input
        type="text"
        id="assignmentName"
        value={assignmentName}
        onChange={(e) => setAssignmentName(e.target.value)}
        placeholder="Enter assignment name"
      />
    </FormGroup>

    <FormGroup>
      <Label for="assignmentInstruction">Assignment Instruction</Label>
      <ReactQuill
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
          ],
        }}
        id="assignmentInstruction"
        value={assignmentInstruction}
        onChange={(value) => setAssignmentInstruction(value)}
        style={{ marginBottom: '10px' }}
      />
    </FormGroup>

    <h6>Existing Resources</h6>
    <ListGroup>
  {existingResources.map((resource, index) => (
    <ListGroupItem
      key={index}
      className="d-flex justify-content-between align-items-center"
    >
      <div className="d-flex align-items-center">
        <Input
          type="checkbox"
          checked={selectedResources.some(
            (res) => res.resource_id === resource.resource_id
          )}
          className="levelChk"
          onChange={() => handleResourceSelection(resource)}
          style={{ accentColor: 'blue' }}
        />
        <span style={{ marginLeft: '10px' }}>{resource.resource_name}</span>
      </div>
      {selectedResources.some((res) => res.resource_id === resource.resource_id) && (
        <Trash
          size={16}
          onClick={() => handleTrashClick(resource)}
          className="ml-auto" // Bootstrap class to push it to the right
          style={{ cursor: 'pointer',}}
        />
      )}
  
    </ListGroupItem>
  ))}
</ListGroup>

  </ModalBody>
  <ModalFooter>
    <Button color="primary" onClick={handleAddAssignment} disabled={isLoading}>
      {isLoading ? (isEditing ? 'Editing...' : 'Adding...') : (isEditing ? 'Save Changes' : 'Save Assignment')}
    </Button>
    <Button color="secondary" onClick={toggleModal}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>
<Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the resource <b>{resourceToDelete?.resource_name}</b>?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDelete}>
            Delete
          </Button>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isRubricsModalOpen} toggle={toggleRubricsModal}>
  <ModalHeader toggle={toggleRubricsModal}>Add Rubrics</ModalHeader>
  <ModalBody>
    {rubrics.length === 0 ? (
      <p>No Data</p>
    ) : (
      <ListGroup>
        {rubrics.map((rubric) => (
          <ListGroupItem
            key={rubric.rubric_id}
            className="d-flex justify-content-between align-items-center"
          >
            <Label check>
              <Input
                type="checkbox"
                className="levelChk"
                checked={
                  selectedRubrics.includes(rubric.rubric_id) ||
                  assignRubrics.some((r) => r.rubric_id === rubric.rubric_id) // Check if already assigned
                }
                onChange={() => handleRubricSelection(rubric.rubric_id)}
              />
              <span style={{ marginLeft: "10px" }}>{rubric.rubric_name}</span>
            </Label>
          </ListGroupItem>
        ))}
      </ListGroup>
    )}
  </ModalBody>
  <ModalFooter>
    <Button color="primary" onClick={handleAddRubricsToAssignment}>
      Save Rubrics
    </Button>
    <Button color="secondary" onClick={toggleRubricsModal}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>



    </div>
  );
};

export default AddAssignmentCard;
