import React, { useState, useEffect , useRef } from 'react';
import {Button, Card, CardBody, Col, Container,CardHeader, Row, Input, FormGroup, Label, Collapse, Nav, NavItem, NavLink, Spinner, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Video, Music, FileText , } from 'react-feather';
import AddResourceCard from './AddResourceModal';
import AddTopicCard from './AddTopicModal';
import classnames from 'classnames'; // To handle active tab classes
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { CiEdit } from "react-icons/ci";
import {  PiStudentDuotone } from "react-icons/pi";
import SonglistCard from './SongListModal';
import { Trash ,Eye } from 'react-feather';
import AddAssignmentCard from './AddAssignment';
import {ChevronDown} from 'react-feather';
import ReactQuill from 'react-quill';
import PreviewComponent from './PreviewComponent';
import AddLevelModal from './AddLevelModal';
import { Plus } from 'react-feather';
import { MdOutlineCancel } from "react-icons/md";
import LessonRubrics from './lessonRubrics';


import Search from './search';
import { toast } from "react-toastify";
import './style.scss';
import VideoResourceCard from './VideoResourceCard';
import AddLessonModal from './AddLessonModal'; 
import { addLesson, getLessons, getTopics, addTopic, signedURL, uploadResourse, getTopicWithResource, addAssignment, updateLesson, removeTopicResource, addExistingResourceToTopic, deleteAssignmentFromTopic, editTopic } from '../../../../Api/lms/apiUtils'; 
import { _addResource } from '../../../../Api/lms/utility'; 
import { H1, H2, H3, H4, H5,H6 } from '../../../../AbstractElements';


interface Resource {
  resource_id: number;
  resource_name: string;
  type: string;
  url: string;
}

interface Topic {
  description:any;
  topic_name: string;
  topic_id: number;
  resources?: Resource[];
}

interface Song {
  
}

interface Resource {

  name: any;
  file: File;
  display_name: any;
}

interface Lesson {
  lesson_number: number;
  topics: Topic[];
  level_id: number;
}

interface ArtiumCourseManagerProps {
  selectedCourse: string;
  courseDetail: any;
  selectedLevel: string;
  courseLevels: any;
  fetchCourses:any;
}

const ReplaceableResourceCard: React.FC<{
  resource: Resource;
  onSave: (updatedResource: Resource) => void;
  onDelete: (resource: Resource) => void;
}> = ({ resource, onSave, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Manage preview state
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
const [resourceToDelete, setResourceToDelete] = useState<Resource | null>(null);



  const handleToggle = () => setIsOpen(!isOpen);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setNewFile(e.target.files[0]);
    }
  };

  const handleSave = async() => {
    if (newFile) {
      const updatedResource = {
        ...resource,
        resource_name: newFile.name,
        url: URL.createObjectURL(newFile), // For demo purposes, using object URL
      };
      try {
        const fileExtension = newFile.name.split('.').pop();
        const response = await signedURL({ fileName: newFile.name, fileType: newFile.type, fileExtension });
        if (response.status === 200) {
          const { signedUrl } = response.data;
          const uploadResponse = await uploadResourse(signedUrl, newFile);
          if (uploadResponse.status === 200) {
        console.log('File uploaded successfully', uploadResponse);
          } else {
        console.log('Error uploading file', uploadResponse);
          }
        }
      } catch (error) {
        console.error('Error during file upload process', error);
      }
      //onSave(updatedResource);
    }
    setIsOpen(false);
  };

  const handlePreviewToggle = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };

  const openDeleteModal = (resource: Resource) => {
    setResourceToDelete(resource);
    setIsDeleteModalOpen(true);
  };
  
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setResourceToDelete(null);
  };
  


  return (
    //this is code for collapse 
    <>
    <Card style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px' }}>
      <CardBody>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div onClick={handleToggle} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            {resource.type === 'video' && <Video color="grey" />}
            {resource.type === 'audio' && <Music color="grey" />}
            {resource.type === 'article' && <FileText color="grey" />}
            {resource.type === 'pdf' && <FileText color="grey" />}
            {resource.type === 'mp3' && <Music color="grey" />}
            {resource.type === 'mp4' && <Video color="grey" />}

            <span style={{ marginLeft: '10px' }}>{resource.resource_name}</span>
          </div>
          <div>
            <Button color="secondary" onClick={handlePreviewToggle}>
              <Eye size={18} />
            </Button>
            <Button color="secondary" onClick={() => openDeleteModal(resource)}>
  <Trash size={18} />
</Button>
          </div>
          <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal}>
        <ModalHeader toggle={closeDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the resource: <strong>{resource.resource_name}</strong>?
        </ModalBody>
        <ModalFooter>
        <Button 
  color="danger" 
  onClick={() => {
    onDelete(resource);
    setIsDeleteModalOpen(false); // Close the modal
  }}
>
  Delete
</Button>

          <Button color="secondary" onClick={closeDeleteModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
        </div>
      </CardBody>
    </Card>

    {/* Preview Component */}
    <PreviewComponent
      resource={resource}
      isOpen={isPreviewOpen}
      onClose={handlePreviewToggle} // Close preview when necessary
    />
     {/* <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal}>
        <ModalHeader toggle={closeDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the resource "{resourceToDelete?.resource_name}"?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteResource}>Delete</Button>
          <Button color="secondary" onClick={closeDeleteModal}>Cancel</Button>
        </ModalFooter>
      </Modal> */}
  </>
  );
};


const ArtiumCourseManager: React.FC<ArtiumCourseManagerProps> = ({  courseLevels,courseDetail, selectedCourse = "Course Title", selectedLevel }) => {
  console.log("coursse", courseDetail);
  const [selectedTopic, setSelectedTopic] = useState<any | null>(null);
  const [isAddingResources, setIsAddingResources] = useState<boolean>(false);
  const [clickTopicModal, setClickTopicModal ]=useState<boolean>(false);
  const[isAddingSong , setAddingSong]  = useState<boolean>(false);
  const [expandedLesson, setExpandedLesson] = useState<number | null>(null);
  const [isAddingTopic, setIsAddingTopic] = useState<boolean>(false);
  const [isAddingLesson, setIsAddingLesson] = useState<boolean>(false);
  const [isAddingAssignment, setIsAddingAssignment] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [summary, setSummary] = useState<any>('');
  const [lessonObjective, setLessonObjective] = useState<string>('');
  const [lessonName, setLessonName]= useState<any>('');
  const [lessonNumber, setLessonNumber]= useState<any>('');
  const [lessonProcedure, setLessonProcedure] = useState<string>('');
  const [lessonClosure, setLessonClosure] = useState<string>('');
  const [selectedTopicIndex, setSelectedTopicIndex] = useState<any| null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPreviewVisible, setPreviewVisible] = useState(false); // State to toggle the preview
  const [previewResource, setPreviewResource] = useState(null);
  const [newResourceType, setNewResourceType] = useState<'video' | 'audio' | 'pdf'>('video');
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
;  const [resources, setResources] = useState<Resource[]>([]);
  const [learningOutcomes, setLearningOutcomes] = useState<any>('');
  const [lessons, setLessons] = useState<LessonType[]>([]);
  const [displayName, setDisplayName] = useState<string>('');
  const [topics, setTopics] = useState([]);
  const [savedTopic, setSavedTopic] = useState({}); // for addassignment 
  const [activeTab, setActiveTab] = useState<number>(courseDetail?.levels[0]?.level_id); // Set the first level as default
  const [isOpen, setIsOpen] = useState(false); // State for managing collapse
  const [isOpenAssign, setIsOpenAssign] = useState(false);
  const [isOpenResource, setIsOpenResource] = useState(false);  // State to handle collapse toggle
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [resourceFiles, setResourceFiles] = useState<FileList | null>(null);
  const [editorContent, setEditorContent] = useState<string>('');
  const [isUploading, setIsUploading] = useState(false); 
  const [updatedDescription, setUpdatedDescription] = useState((selectedTopic as any)?.topic.description);
  const [topicName, setTopicName] = useState<any>('');
  const [updatedCourseDetail, setUpdatedCourseDetail] = useState(courseDetail);
    const [isExtended, setIsExtended] = useState<boolean>(false); 
    const [artiumGrade, setArtiumGrade] = useState<boolean>(false);
    const [trinityGrade, setTrinityGrade] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
const [assignmentToDelete, setAssignmentToDelete] = useState<{ topicId: number; assignmentId: number } | null>(null);
const [isHovered, setIsHovered] = useState(false);
const [selectedResourceId, setSelectedResourceId] = useState<number | null>(null);
const [isModalOpen, setIsModalOpen] = useState(false);
const [currentView, setCurrentView] = useState('');
const [isAddLevelModalOpen, setIsAddLevelModalOpen] = useState(false);
const [isFileModalOpen, setFileModalOpen] = useState(false);
const [currentLessonId, setCurrentLessonId] = useState<number | null>(null);
const [fileInputs, setFileInputs] = useState<{ file: File; displayName: any }[]>([]);
const [nestedTab, setNestedTab] = useState<number>(1);


const toggleModal = () => setIsModalOpen(!isModalOpen);

const handleOpenDeleteModal = (resource_id: number) => {
  setSelectedResourceId(resource_id);
  toggleModal();
};

type LessonType = {
  lesson_id: number;
  lesson_number: string;
  lesson_name: string;
  summary: string;
  objective: string;
  lesson_procedure: string;
  lesson_closure: string;
  outcome: string;
  // Add other properties as needed
};

const toggleFileModal = () => setFileModalOpen(!isFileModalOpen);
const toggleCollapseResource = () => setIsOpenResource(!isOpenResource); 

  const toggleCollapse = () => setIsOpenAssign(!isOpenAssign); 

  const toggle = () => setIsOpen(!isOpen)

  const filteredLessons = lessons.filter((lesson:any) => lesson.level_id === activeTab);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const inputs = selectedFiles.map(file => ({
        file,
        displayName: file.name.split('.')[0] // Initial display name
      }));
      setFileInputs(inputs);
    }
  };

  const updateCourseLevels = (newLevels : any) => {
    setUpdatedCourseDetail((prevDetail : any) => ({
      ...prevDetail,
      levels: [...prevDetail.levels, ...newLevels],
    }));
  };
 
  const openDeleteModal = (topicId: number, assignmentId: number) => {
    setAssignmentToDelete({ topicId, assignmentId });
    setIsDeleteModalOpen(true);
  };
  (alert)
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setAssignmentToDelete(null);
    // setCurrentView("");
 
  };
  
  const toggleAddLevelModal = () => setIsAddLevelModalOpen(!isAddLevelModalOpen);
  const toggleTab = async (tab: number) => {
    if (activeTab !== tab) {
      setIsLoading(true);
      await resetview(); 
      setActiveTab(tab); 

      setIsLoading(false); 
    }
  };



  const toggleNestedTab = (tabId: number) => {
    if (nestedTab !== tabId) setNestedTab(tabId);
  };

  useEffect(() => {
    if (selectedTopic) {
      console.log( selectedTopic.topic.topic_id,"topicid")
      console.log('Selected Topic Updated:', selectedTopic);
    } else {
      console.log('No data in selectedTopic');
    }
  }, [selectedTopic]); 
 

  const resetview = () => {
    return new Promise((resolve) => {
      setAddingSong(false);
      setIsAddingResources(false);
      setIsAddingTopic(false);
      setIsAddingLesson(false);
      setExpandedLesson(null);
      setIsEditing(false);
      setSummary('');
      setLearningOutcomes('');
      setSelectedTopic(null);
      setTopics([]);
    // Clear topics
      setLessons([]);

      // Simulate a delay to show the loader, then resolve the promise
      setTimeout(() => resolve(true), 500); // Adjust delay as needed
    });
  };
  const fetchLessons = async () => {
    try {
      const response = await getLessons(activeTab);
      if (response.lessons?.length) {
        setLessons(response.lessons);
      } else {
        console.error('Failed to fetch lessons:', response.message);
      }
    } catch (error) {
      console.error('Error fetching lessons:', error);
    }
  };
  const fetchTopics = async (lessonId:any, topic:any = "") => {
    try {
      const response = await getTopics(lessonId);
      if (response.topics?.length) {
        setTopics(response.topics); // Set topics from API
        if(topic){
          let _index;
          let _topic = {};
          response.topics.map((t:any, i:any) => {
            if(t.topic_id === topic.topic_id){
              _index = i;
              _topic = t;
            }
          });
          handleTopicClick((_topic as any), _index);
        }
      } else {
        setTopics([]); 
        console.error('Failed to fetch topics:', response.message);
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };
  useEffect(() => {
    fetchLessons();
    resetview();
  }, [activeTab]);
 
  const handleEditLesson = (lessonId: number) => {
    console.log("currentId", currentLessonId)
    const lesson = lessons.find((l:any) => l.lesson_id === lessonId);
    if (lesson) {
      setSummary((lesson as any).summary);
      setLessonObjective((lesson as any).objective);
      setLearningOutcomes(((lesson as any) as any).outcome);
      setLessonName((lesson as any).lesson_name);
      setLessonNumber((lesson as any).lesson_number);
      setLessonProcedure(((lesson as any) as any).lesson_procedure);
      setLessonClosure((lesson as any).lesson_closure);
      setIsEditing(true);
      setCurrentLessonId(lessonId);
      setCurrentView('view_edit_Lesson');
    }
  };

  
  const handleChange = (content: string, delta: any, source: string, editor: any) => {
    if (source === 'user') {
      // Check if the delta contains a paste operation
      const pastedContent = delta.ops?.some((op: any) => op.insert && typeof op.insert === 'string');
      if (pastedContent) {
        // Apply Heading 3 formatting to the entire pasted content
        setTimeout(() => {
          const quill = editor.getEditor();
          quill.format('header', 3);
        }, 0); // Defer formatting to ensure paste operation is completed
      }
    }
    setEditorContent(content);
  };

  

  const handleSave = async () => {
    if (!currentLessonId) {
      toast.error('No lesson selected for editing');
      return;
    }
  
    let reqParam = {
      levelId: activeTab, 
      lessonNumber: lessonNumber,
      lessonName: lessonName,
      summary: summary,
      objective: lessonObjective,
      lessonProcedure: lessonProcedure, 
      lessonClosure: lessonClosure, 
      outcome: learningOutcomes
    };
    try {
      const response = await updateLesson(currentLessonId, reqParam);
      if (response.status === 200) {
        toast.success('Lesson updated successfully');
        let cloneLessons = [...lessons];
        const updatedLessons = cloneLessons.map(lesson => {
          if ((lesson as any).lesson_id === currentLessonId) {
            return { 
              ...lesson, 
              lesson_number: lessonNumber,
              lesson_name: lessonName,
              summary: summary,
              objective: lessonObjective,
              lesson_procedure: lessonProcedure,
              lesson_closure: lessonClosure,
              outcome: learningOutcomes
            };
          }
          return lesson;
        });
  
        setLessons(updatedLessons);        
        setIsEditing(false);
        setCurrentLessonId(null); // Clear after saving
      } else {
        toast.error('Failed to update lesson:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating lesson:', error);
    }
  };
  


  
  const handleToggleSongCard = () => {
    setAddingSong(false);

  };
  const handleAddSongClick = () => {
    setIsUploading(true);
    setCurrentView('view_level_song');  
    setAddingSong(true); // Handler to toggle the visibility of the SongListCard
  };

const OnCancelResourse =  () =>{
  setIsAddingResources(false);

}
const handleAddExistingResourceToTopic = async (resources: any) => { 
  try {
    const response = await addExistingResourceToTopic(
      {
        "topicId": selectedTopic.topic.topic_id,
        "resourceIds": resources.map((resource: any) => ( resource.resource_id))
     }
    );
    if (response.status === 200) {
      console.log('Resource added successfully');
      toast.success ('Resource added successfully');
      fetchTopicWithResources(selectedTopic.topic.topic_id);
      setIsUploading(false);
      // const updatedTopic = {
      //   ...selectedTopic,
      //   resources: [...(selectedTopic.resources || []), ...[resources]],
        
      // };
      // setSelectedTopic(updatedTopic);
    } else {
      console.error('Failed to add resource:', response.data.message);
    }
  } catch (error) {
    console.error('Error adding resource:', error);
  }
}

const fileInputRef = useRef<HTMLInputElement | null>(null);

const clearFileInput = () => {
  if (fileInputRef.current) {
    fileInputRef.current.value = ''; // This resets the input field.
  }
};
const handleAddResources = async () => {
  if (fileInputs.length === 0 || !selectedTopic) {
    toast.error('Please select files and a topic.');
    return;
  }

  setIsUploading(true);

  try {
    let addedResources = [];

    // Iterate over each file and upload individually
    for (const input of fileInputs) {
      const { file, displayName } = input;

      // Upload the resource
      let resourceRes = await _addResource([file], displayName);
      if (resourceRes) {
        addedResources.push(resourceRes);
      }
    }

    // Get all resource IDs for the added resources
    const resourceIds = addedResources.map(res => (res as any).resource_id);

    // Associate all uploaded resources with the selected topic
    let topicRes = await addExistingResourceToTopic({
      topicId: selectedTopic.topic.topic_id,
      resourceIds: resourceIds
    });

    if (topicRes.status === 200) {
      toast.success('Resources added successfully');

      // Update the selected topic with the new resources
      const updatedTopic = {
        ...selectedTopic,
        resources: [...(selectedTopic.resources || []), ...addedResources],
      };
      setSelectedTopic(updatedTopic);

      // Clear state and reset input
      setFileInputs([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Clear file input
      }
    }
  } catch (error) {
    console.error('Error adding resources:', error);
    toast.error('Error adding resources');
  } finally {
    setIsUploading(false);
  }
};



// Helper function to determine file type
const determineFileType = (file: File): 'video' | 'audio' | 'pdf' => {
  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  if (fileExtension?.match(/(mp4|mov|avi|mkv)/)) {
    return 'video';
  } else if (fileExtension?.match(/(mp3|wav|aac|ogg)/)) {
    return 'audio';
  } else if (fileExtension === 'pdf') {
    return 'pdf';
  }
  throw new Error('Unsupported file type');
};

const fetchTopicWithResources = async (topicId: number) => {
  try {
    const response = await getTopicWithResource(topicId);
    if (response.data) {
      setSelectedTopic(response.data);
      setClickTopicModal(true);
      setIsAddingResources(false);
    } else {
      console.error('Failed to fetch topic with resources:', response.message);
    }
  } catch (error) {
    console.error('Error fetching topic with resources:', error);
  }
};

const handleTopicClick = async (topic: Topic, index: any) => {
  // handleTopicEditSave ();
  setSelectedTopicIndex(index);
  setCurrentView('view_edit_topic');
  setClickTopicModal(true);
  setUpdatedDescription(topic.description);
  setTopicName(topic.topic_name);
  setIsExtended((topic as any).is_extended);
  setArtiumGrade((topic as any).artium_grade);
  setTrinityGrade((topic as any).trinity_grade);
  await fetchTopicWithResources(topic.topic_id);
};

 

  const handleTopicEdit = () => {
    setIsEditingDescription(true);
  };


  const onSaveDescription = (description: any) => {
    // Your logic to handle saving the description
  };
  

  const handleTopicEditSave = async () => {
    // Save the updated description
    let res = await editTopic((selectedTopic as any)?.topic.topic_id,{   
      lessonId: expandedLesson,
      description: updatedDescription,
      topicName: topicName,
      isExtended: isExtended,
      artiumGrade: artiumGrade,
      trinityGrade: trinityGrade
    });
    if(res.status == 200){
      toast.success('Topic updated successfully');
      fetchTopics(expandedLesson);
      setIsEditingDescription(false);
    } else {  
      //alert('Error updating topic');
    }
    //onSaveDescription(updatedDescription);
    //setIsEditingDescription(false);
  };

  const handleEditTopicCancel = () => {
    setCurrentView("");
    setIsEditingDescription(false);
  };

  const handleTopicCancel = () => {
    setClickTopicModal(false); // Close the modal/resource section
    setCurrentView("");
  };

  

  const handleAddTopic = (lessonNumber: number) => {
    setCurrentView('view_add_topic');
    setIsAddingTopic(true);
  };

  const handleAddLessonClick = () => {
    setCurrentView('view_add_song');
    setIsAddingLesson(true);
  };

  const handleSaveLesson = async (newLesson: any) => {
    let reqParam = {
      levelId: activeTab, 
      lessonNumber: newLesson.lessonNumber,
      lessonName: newLesson.name,
      summary: newLesson.summary,
      objective: newLesson.objective,
      lessonProcedure:newLesson.lessonProcedure, 
      lessonClosure:newLesson.lessonClosure, 
      outcome:newLesson.learningOutcomes
    };
    try {
      const response = await addLesson(reqParam);
      if (response.status === 201) {
        toast.success ('Lesson added successfully');
        setLessons(([...lessons, newLesson] as any)); 
        setIsAddingLesson(false); 
        fetchLessons();
      } else {
        console.error('Failed to add lesson:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding lesson:', error);
    }
  };

  

  const handleSaveResources = (newResources: Resource[], content: string) => {
    if (selectedTopic) {
      setSelectedTopic({
        ...selectedTopic,
        resources: [...(selectedTopic.resources || []), ...newResources],
      });
    }
    setIsAddingResources(false);
  };
  

  const handleSaveAssignment = async (assignments: any) => {
    if (selectedTopic || savedTopic) {
      let reqParam = {
        "topicId": (selectedTopic as any)?.topic_id || (savedTopic as any)?.topic_id,
        "name": assignments.name,
        "description": assignments.instructions,
        "resources": assignments.resources.map((resource: any) => ( resource.resource_id))
      };

      // const updatedAssignments = [...(selectedTopic.resources || []), ...assignments.resources];
      // setSelectedTopic({
      //   ...selectedTopic,
      //   resources: updatedAssignments,
      // });

      try {
        const response = await addAssignment(reqParam);
        if (response.status === 201) {
          console.log('Assignment added successfully');
        } else {
          console.error('Failed to add assignment:', response.data.message);
        }
      } catch (error) {
        console.error('Error adding assignment:', error);
      }
    }
    setIsAddingAssignment(false);
  };

  const handleSaveTopic = async (topicName: string, details: string, resources:any,isExtended: boolean, artiumGrade: boolean, trinityGrade: boolean) => {
    let _resources = [];
    _resources = resources.map((resource: any) => {
      // return {
      //   resourceName: resource.resourceName,
      //   type: resource.type,
      //   description: "A video tutorial on lms basics.",
      //   url: ""
      // }
      return resource.resource_id;
    });
    let reqParam = {
      "topicData": {
        "topicName": topicName,
        "description": details,
        "lessonId": expandedLesson,
        "isExtended":!!isExtended,
        "artiumGrade":!!artiumGrade,
        "trinityGrade":!!trinityGrade
      },
      "resources": _resources
    }
    try {
      const response = await addTopic(reqParam);
      if (response.status === 200) {
      setSavedTopic(response.data.topic);
      toast.success ('Topic added successfully');
      fetchTopics(expandedLesson, response.data.topic); // Refresh topics after adding a new one
      //setIsAddingTopic(true);
      setIsAddingTopic(false);

      } else {
      console.error('Failed to add topic:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding topic:', error);
    }
  };

  const handleSaveResource = (updatedResource: Resource) => {
    if (selectedTopic) {
      const updatedResources = selectedTopic.resources?.map((res: Resource) =>
        res.resource_name === updatedResource.resource_name ? updatedResource : res
      );
      setSelectedTopic({ ...selectedTopic, resources: updatedResources });
    }
  };

  const toggleLesson = (lesson: any) => {
    if (expandedLesson === lesson.lesson_id) {
      setExpandedLesson(null);
      setTopics([]);
    } else {
      setExpandedLesson(lesson.lesson_id);
      fetchTopics(lesson.lesson_id); // Fetch topics for the selected lesson
    }
  };


  const handleDisplayNameChange = (index: number, newDisplayName: string) => {
    
    setFileInputs(prevInputs => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].displayName = newDisplayName;
      return updatedInputs;
    });
  };


  const deleteResource = async (topicId: any, resource_id: any) => {
   
   

    try {
      const response = await removeTopicResource(topicId, resource_id);
      if (response.status === 200) {
       toast.success('Resource removed successfully');
        const updatedResources = selectedTopic.resources.filter((res: Resource) => res.resource_id !== resource_id);
        setSelectedTopic({ ...selectedTopic, resources: updatedResources });
      } else {
      toast.error('Failed to remove resource:', response.data.message);
      }
    } catch (error) {
      console.error('Error removing resource:', error);
    }
  };
  const deleteAssignment = async () => {
    if (!assignmentToDelete) return;
  
    const { topicId, assignmentId } = assignmentToDelete;
  
    try {
      console.log('Deleting assignment:', assignmentId);
      let response = await deleteAssignmentFromTopic(topicId, assignmentId);
      if (response.status === 200) {
        fetchTopicWithResources(topicId);
        toast.success('Assignment deleted successfully');
      } else {
        console.error('Error deleting assignment:', response);
        toast.error('There was an error deleting the assignment. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting assignment:', error);
      toast.error('There was an error deleting the assignment. Please try again.');
    } finally {
      closeDeleteModal(); // Close the modal after processing
    }
  };
  
  const courseId = courseDetail.course_id; // Replace this with the actual courseId from your component's state or props





  return (
    <Container fluid>
     <Row className="user-cards-items user-card-wrapper" >
  <Col md="12">


    <div style={{marginTop:"10px", cursor: 'pointer',}}>
 
      {/* Navigation Tabs */}
      <Nav tabs style={{ marginBottom: '20px' }}>
        {courseDetail.levels.map((level: any) => (
          <NavItem key={level.level_id}>
            <NavLink
              // style={activeTab === level.level_id ? { border: "solid 1.5px #bb86fc" } : {}}
              className={classnames({ active: activeTab === level.level_id, default_border: activeTab === level.level_id})}
              onClick={() => toggleTab(level.level_id)}
            >
              {level.level_name}
            </NavLink>
          </NavItem>
        ))}
       <Button onClick={toggleAddLevelModal}>< Plus size={17}/></Button>
      </Nav>
      <AddLevelModal
        isOpen={isAddLevelModalOpen}
        toggle={toggleAddLevelModal}
        courseName={updatedCourseDetail.course_name}
        courseId={updatedCourseDetail.course_id}
        existingLevels={updatedCourseDetail.levels}
        allLevels={courseLevels}
        onLevelsAdded={updateCourseLevels} 
        
        // Pass the update function
      />


      {/* Loader: Show while switching tabs */}
      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spinner color="secondary" />
        </div>
      ) : (
        <div>
          {/* Render the content of the active tab */}
    

          <H4 style={{ marginTop: "10px" }}>
  {courseDetail.course_name} -{' '}
  {
    // Find the level by activeTab and display its name
    courseDetail.levels.find((level: any) => level.level_id === activeTab)?.level_name
  }
  {
    // Get the level_id for the active tab
    courseDetail.levels.find((level: any) => level.level_id === activeTab) && (
      <a
        href={`https://uatreact.artiumacademy.com/videocall/${
          courseDetail.levels.find((level: any) => level.level_id === activeTab)?.level_id
        }`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          className={classnames('custom-cancel-button')}
          color="secondary"
          style={{ marginLeft: "10px" }}
        >
          Preview
        </Button>
      </a>
    )
  }
</H4>

  




         
    <Row className="user-cards-items user-card-wrapper">
        <Col>
          {/* <h4>{courseDetail.course_name} - {activeTab}</h4> */}
        </Col>
      </Row>
     
      <Row className="user-cards-items user-card-wrapper">
     
        <Col md="3" className="side-menu bar" style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '30px' }}>
        {/* <Card >
            <CardBody onClick={handleAddSongClick}>
              <H6 style={{ cursor: 'pointer' }}>Song list</H6>
            </CardBody>
          </Card> */}
        <Card  color='primary' onClick={handleAddLessonClick} style={{marginBottom:"10px", textAlign:"center" }}>
            Add Lesson +
          </Card>
          <Card
  className={classnames({ 'highlighted-button': currentView == "view_level_song"})}  // Apply 'highlighted-button' conditionally
  style={{ marginBottom: '10px', textAlign: 'center', cursor: 'pointer' }}
  onClick={handleAddSongClick}
>
  Song List
</Card>
          {lessons.map((lesson: any) => (
            <Card key={lesson.lesson_id} className="mb-3 card">
              <CardBody>
              <div style={{ position: 'absolute', top: '-5px', left: '-20px' }}>
              <Button 
          color="link" 
          onClick={() => handleEditLesson(lesson.lesson_id)} 
        >
          <CiEdit size={22} />
        </Button>
      </div>

   
            
            
                <h5 onClick={() => toggleLesson(lesson)} style={{ cursor: 'pointer' }}>
                  Lesson {lesson.lesson_number}
                  {/* <ChevronDown style={{   marginLeft: '8px', verticalAlign: 'middle', transform: expandedLesson === lesson.lesson_id?  'rotate(180deg)' : 'rotate(0)' }}/> */}

<Button
          color="link"
          onClick={toggleLesson}
          style={{ float: 'right', padding: '0', textDecoration: 'none' }}
        >
          {expandedLesson === lesson.lesson_id? '▼' : '►'}
        </Button>

                  <h6>{lesson.lesson_name}</h6>
                </h5>

                <Collapse isOpen={expandedLesson === lesson.lesson_id}>
                  {topics.map((topic: any, topicIndex: number) => (
                    <div key={topicIndex}>
                 <Card 
  onClick={() => handleTopicClick(topic, topicIndex)}
  className={classnames({ default_border: selectedTopicIndex === topicIndex })}
  style={{
    cursor: 'pointer',
    marginBottom: '1rem',
    marginTop: '10px'
  }}
>
  <CardBody>
    <h6>
      <b>Topic Number: </b>{topicIndex + 1} {/* Display topic number */}
      <br />
      <b>Topic Name: </b>{topic.topic_name}
    </h6>
  </CardBody>
</Card>

                    </div>
                  ))}
                  <Button color="primary" onClick={() => handleAddTopic(lesson.lesson_id)} style={{ marginTop: '1rem' }}>
                    Add Topic +
                  </Button>
                </Collapse>
              </CardBody>
            </Card>
          ))}

      
        </Col>

        <Col md="9" style={{ padding: '2rem' }}>
        {isEditing  &&  currentView == "view_edit_Lesson" && (
  <Card className="mb-3">
    <CardBody style={{ maxHeight: '60vh', overflowY: 'auto' }}>
      <h2  style={{color:"#e82c86"}} >Edit Lesson</h2>
      <h6  color="primary"  style={{ marginBottom: '10px', marginTop:"15px"  }}>
        <b>Lesson Number:</b>
        <Input 
          style={{ marginTop: '10px' }} 
          type="text" 
          value={lessonNumber} 
          onChange={(e) => setLessonNumber(e.target.value)} 
        />
      </h6> 

      <h6 style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <b>Lesson Name:</b>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PiStudentDuotone size={20} style={{ marginRight: '5px' }} />
          <LiaChalkboardTeacherSolid size={20} />
        </div>
      </h6>
      <Input
        style={{ marginTop: '10px' }}
        type="text"
        id="lessonName"
        value={lessonName}
        onChange={(e) => setLessonName(e.target.value)}
      />

      <h6 style={{ marginBottom: '10px', marginTop:"10px" }}>
        <b>Lesson Summary:</b>
        <ReactQuill style={{ marginTop: '10px' }} value={summary} onChange={setSummary} modules={{
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Add toolbar options
          ['bold', 'italic', 'underline'],
          ['link', 'image'],
        ],
      }} />
      </h6>

      <h6 style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <b> Learning Objective:</b>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PiStudentDuotone size={20} style={{ marginRight: '5px' }} />
          <LiaChalkboardTeacherSolid size={20} />
        </div>
      </h6>
      <ReactQuill
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Add toolbar options
          ['bold', 'italic', 'underline'],
          ['link', 'image'],
        ],
      }}
        style={{ marginTop: '10px' }}
        value={lessonObjective}
        onChange={setLessonObjective}
      />

      <h6 style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop:"10px"}}>
        <b>Lesson Procedure:</b>
        <LiaChalkboardTeacherSolid size={20} />
      </h6>
      <ReactQuill
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Add toolbar options
          ['bold', 'italic', 'underline'],
          ['link', 'image'],
        ],
      }}
        style={{ marginTop: '10px' }}
        value={lessonProcedure}
        onChange={setLessonProcedure}
      />

      <h6 style={{ marginBottom: '10px', marginTop:"10px" }}>
        <b>Lesson Closure:</b>
        <ReactQuill style={{ marginTop: '10px' }} 
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, false] }], // Add toolbar options
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
          ],
        }}
        value={lessonClosure} onChange={setLessonClosure} />
      </h6>

      <h6 style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop:"10px"}}>
        <b>Learning Outcomes:</b>
        <LiaChalkboardTeacherSolid size={20} />
        </h6>
        <ReactQuill style={{ marginTop: '10px' }} 
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, false] }], // Add toolbar options
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
          ],
        }}
        value={learningOutcomes} onChange={setLearningOutcomes} />
       
<span style={{marginTop:"10px"}}>     <b>Rubrics:</b></span>
<div 
  style={{
    marginTop: '10px',
    border: '1px solid lightgray',  // Gray border
         // Optional: Rounded corners
    padding: '10px',            // Optional: Padding inside the div
  }}
>
  <div 
    style={{
      maxHeight: '70vh',
      overflowY: 'auto',
    }}
  >  
    <h6 
      style={{
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <LessonRubrics
        courseDetail={courseDetail}
        lessonId={currentLessonId}
        // Optional chaining to prevent errors
      />
    </h6>
  </div>
</div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
      <Button color="primary" onClick={handleSave}>
  Save
</Button>

        <Button className="custom-cancel-button" onClick={() => setIsEditing(false)}  toggle={() => setCurrentView("")} >
          Cancel
        </Button>
      </div>
    </CardBody>
  </Card>
        )}

{selectedTopic && currentView == "view_edit_topic" && (
        <Card className="mb-3" style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px' }}>
              <Button onClick={handleTopicCancel} style={{ marginTop: '20px',  color: "#e82c86", justifyContent:"flex-end", display:"flex" }}>
            <MdOutlineCancel size={24} />
            </Button>
          <CardBody>
     
            <Card>
          
              <CardBody style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <h3 style={{ margin: 0 }}>
                    <b>Topic:</b> {topicName}
                  </h3>
                  <Button color="link" onClick={handleTopicEdit} style={{ marginLeft: '10px' }}>
                  <CiEdit size={24} />
                  </Button>
                </div>

                {/* Description Section */}
                <div style={{ marginTop: '10px' }}>
                  {/* <b>Description: </b> */}

                  {/* If editing, show the rich text editor */}
                  {isEditingDescription ? (
                    <div>
                      <FormGroup>
  <Label for="topicName" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <b>Topic Name:</b>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <PiStudentDuotone size={20} style={{ marginRight: '5px' }} />
      <LiaChalkboardTeacherSolid size={20} />
    </div>
  </Label>
  <Input
    type="text"
    id="topicName"
    value={topicName}
    onChange={(e) => setTopicName(e.target.value)}
  />
</FormGroup>
<FormGroup>
  <Label style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <b>Description:</b>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <PiStudentDuotone size={20} style={{ marginRight: '5px' }} />
      <LiaChalkboardTeacherSolid size={20} />
    </div>
  </Label>
  <ReactQuill 
  modules={{
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Add toolbar options
      ['bold', 'italic', 'underline'],
      ['link', 'image'],
    ],
  }}
    value={updatedDescription}
    onChange={setUpdatedDescription}
    style={{ marginBottom: '10px' }}
  />
</FormGroup>

                      {/* Additional Checkboxes */}
        <FormGroup>
          <Label>Additional Options:</Label>
          <Row form>
            <Col md={4}>
              <Input
                type="checkbox"
                checked={isExtended}
                className='levelChk'
                onChange={(e) => setIsExtended(e.target.checked)}
              />
              <Label style={{ marginLeft: '10px' }}> Is Extended</Label>
            </Col>
          
          </Row>
        </FormGroup>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button color="primary" onClick={handleTopicEditSave} style={{ marginRight: '10px' }}>
                          Save
                        </Button>
                        <Button className="custom-cancel-button" onClick={handleEditTopicCancel}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* Display the description in HTML format */}
                      <span dangerouslySetInnerHTML={{ __html: updatedDescription }} />
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>

            {/* Resources Section */}
            <Card>
              <CardBody style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px' }}>
                <div onClick={toggle} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                  <h5>
                    Resources <ChevronDown style={{ marginLeft: '8px', verticalAlign: 'middle', transform: isOpen ? 'rotate(180deg)' : 'rotate(0)' }} />
                  </h5>
                </div>

                <Collapse isOpen={isOpen}>
                <div>
     <div>
                    {selectedTopic.resources?.map((resource: any, index: number) => (
                      <ReplaceableResourceCard key={index} resource={resource} onDelete={() => deleteResource(selectedTopic.topic.topic_id, resource.resource_id)} onSave={handleSaveResource} />
                    ))}
                  </div>

    
    </div>
                  
                  <div>
   

                  <Modal isOpen={isFileModalOpen} toggle={toggleFileModal}>
  <ModalHeader toggle={toggleFileModal}>Add Existing Resources</ModalHeader> {/* Pass the correct toggle function */}
  <ModalBody>
    {/* Add any existing resources list here, e.g., a list or table */}
    <Search
      selectedTopic={selectedTopic}
      handleAddExistingResourceToTopic={handleAddExistingResourceToTopic}
      setResources={setResources}
    />
  </ModalBody>
</Modal>

      {/* </Card> */}
    </div>
                  <Card style={{ marginTop: "10px" }}>
                    <CardBody>
                      {/* <h5>Add New Resources</h5> */}
                      <div >
                        {/* <FormGroup style={{ flex: 1, marginRight: '10px' }}>
                          <Label for="displayName">Display Name</Label>
                          <Input
                            type="text"
                            placeholder="Display Name (optional)"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)} // Allow user to change display name
                          />
                        </FormGroup> */}
                        <FormGroup style={{ flex: 1, marginRight: '10px' }}>
                          <Label for="fileUpload">Choose File</Label>
                          <input
                              ref={fileInputRef}
                            type="file"
                            id="fileUpload"
                            onChange={handleFileChange} // Call file change handler
                            multiple
                            accept="video/*,audio/*,application/pdf"
                            className="form-control"
                          />
                           {fileInputs.map((input, index) => (
              <div key={index} style={{ marginTop: '10px' }}>
                {/* <p>{input.file.name}</p> */}
                <Input
                  type="text"
                  value={input.displayName}
                  onChange={(e) => handleDisplayNameChange(index, e.target.value)}
                  placeholder="Display Name"
                />
              </div>
            ))}
                        </FormGroup>

                        <Button 
  color="primary" 
  onClick={handleAddResources}
  style={{ marginTop: "6px" }}
  disabled={isUploading}
>
  {isUploading ? <Spinner size="sm" /> : 'Add Resources'}
</Button>


{/* <Button
                          style={{ marginTop: "6px" }}
                          color="primary"
                          onClick={handleAddResources}
                        >
                          Save Resources
                        </Button> */}
        
          
                      </div>
                  <div style={{display:"flex", justifyContent:"center",}} >    <H5  >Or</H5></div>

    <div style={{display:"flex", justifyContent:"center" ,marginTop:"10px"}}>                  <Button className={classnames('custom-cancel-button')} 
      onClick={toggleFileModal}
        >
        Add Existing Resources
        </Button></div>
                  
                    </CardBody>
               
                  </Card>
                </Collapse>
  
              </CardBody>
              {/* {resources.map((resource, index) => (
              <li key={index}>
                {resource.type.toUpperCase()}: {resource.name || (resource as any).display_name ||(resource as any).resource_name }
              </li>
            ))} */}
            </Card>
  

            {/* Assignments Section */}
            <Card style={{ marginTop: "10px" }}>
              <CardBody>
                <div>
                  <div onClick={toggleCollapse} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    <h5>
                      Assignments <ChevronDown style={{ marginLeft: '8px', verticalAlign: 'middle', transform: isOpenAssign ? 'rotate(180deg)' : 'rotate(0)' }} />
                    </h5>
                  </div>

                  <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal}>
  <ModalHeader toggle={closeDeleteModal}>Confirm Deletion</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this assignment?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={deleteAssignment}>Delete</Button>
    <Button color="secondary" onClick={closeDeleteModal}>Cancel</Button>
  </ModalFooter>
</Modal>


                  <Collapse isOpen={isOpenAssign}>
                
                   
                        {/* AddAssignmentCard Component */}
                        <AddAssignmentCard
                        deleteAssignment={deleteAssignment}
                        openDeleteModal={openDeleteModal}
                      topicId={selectedTopic.topic.topic_id}
                      lessonId={selectedTopic.topic.lesson_id}
                      onSave={(newAssignments) => {
                        // handleSaveAssignment(newAssignments);
                        fetchTopicWithResources(selectedTopic.topic.topic_id);
                      }}
                      onCancel={() => toggleCollapse()} // Close collapse on cancel
                      //toggle={() => toggleCollapse()}
                      existingResources={selectedTopic.resources || []} // Pass existing resources for reuse
                      topic={selectedTopic || {}} // Pass the entire topic object if needed elsewhere
          
                      courseId={courseDetail.course_id}
                    />

                 
                  </Collapse>
                </div>
              </CardBody>
            </Card>

            <Button className="custom-cancel-button" onClick={handleTopicCancel} style={{ marginTop: '20px' }}>
              Cancel
            </Button>
            
          </CardBody>
        </Card>
      )}
   


          {isAddingResources && !isAddingAssignment && <AddResourceCard onSave={()=>{}}toggle={() => setCurrentView("")}  />}

          {currentView == "view_level_song" && <SonglistCard levelId={activeTab} toggle={() => setCurrentView("")} />} 

          {currentView == "view_add_topic" && <AddTopicCard savedTopic={savedTopic} onSaveAssignment={handleSaveAssignment} toggle={() => setCurrentView("")} onSaveTopic={handleSaveTopic} />}

          {currentView == "view_add_song" && (
  <AddLessonModal

    isOpen={isAddingLesson}
    toggle={() => setCurrentView("")}
    onSave={handleSaveLesson}
// Pass the courseDetail prop
  />
)}

        </Col>
      </Row>
        </div>
      )}

    </div>

   
  </Col>
</Row>


 
    </Container>
  );
};

export default ArtiumCourseManager;
