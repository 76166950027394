import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { Button, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import './style.scss';
import { LiaChalkboardTeacherSolid } from 'react-icons/lia';
import { PiStudentDuotone } from 'react-icons/pi';
import { toast } from "react-toastify";
import { MdOutlineCancel } from "react-icons/md";
import LessonRubrics from './lessonRubrics';

interface AddLessonModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSave: (newLesson: any) => void;
}

const AddLessonModal: React.FC<AddLessonModalProps> = ({ isOpen, toggle, onSave }) => {
  const [lessonName, setLessonName] = useState<string>('');
  const [lessonNumber, setLessonNumber] = useState<string>('');
  const [lessonSummary, setLessonSummary] = useState<string>('');
  const [lessonObjective, setLessonObjective] = useState<string>('');
  const [learningOutcomes, setLearningOutcomes] = useState<string>('');
  const [lessonProcedure, setLessonProcedure] = useState<string>('');
  const [lessonClosure, setLessonClosure] = useState<string>('');
  const [error, setError] = useState<string>(''); // Error state to track validation errors

  const handleSave = () => {
    // Check if all required fields are filled
    if (
      !lessonNumber ||
      !lessonName ||
      !lessonSummary ||
      !lessonObjective ||
      !learningOutcomes ||
      !lessonProcedure ||
      !lessonClosure
    ) {
    toast.error
    ('Please fill all the mandatory fields');
      return;
    }

    // Clear error message if all fields are filled
    setError('');

    // Proceed with saving the lesson
    const newLesson = {
      lessonNumber,
      name: lessonName,
      summary: lessonSummary,
      objective: lessonObjective,
      learningOutcomes,
      lessonProcedure,
      lessonClosure,
      topics: [], // Start with an empty topic list
    };

    onSave(newLesson);
    toggle(); // Close modal after saving
  };

  return (
    <Card className="mb-3">
      <CardBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> <h2 style={{color:"#e82c86"}} >Add Lesson</h2>
        <Button style={{ marginLeft: '10px',  color: "#e82c86" }} onClick={toggle}>
         <MdOutlineCancel size={22} />
          </Button></div>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        
        <FormGroup style={{marginTop:"15px"}}>
          <Label for="lessonNumber"> 
            <b  >
              Lesson Number
              <span style={{ color: 'red' }}>*</span>
            </b>
          </Label>
          <Input
            type="text"
            id="lessonNumber"
            value={lessonNumber}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setLessonNumber(value);
              }
            }}
          />
        </FormGroup>

        <FormGroup>
          <Label
            for="lessonName"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Lesson Name <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PiStudentDuotone size={20} style={{ marginRight: '10px' }} />
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <Input
            type="text"
            id="lessonName"
            value={lessonName}
            onChange={(e) => setLessonName(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Label
            for="lessonSummary"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Lesson Summary
              <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <ReactQuill value={lessonSummary} onChange={setLessonSummary} />
        </FormGroup>

        <FormGroup>
          <Label
            for="lessonObjective"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Learning Objective
              <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PiStudentDuotone size={20} style={{ marginRight: '10px' }} />
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <ReactQuill value={lessonObjective} onChange={setLessonObjective} style={{ marginTop: '10px' }} />
        </FormGroup>

        <FormGroup>
          <Label
            for="learningOutcomes"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Learning Outcomes
              <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <ReactQuill
            style={{ marginTop: '10px' }}
            value={learningOutcomes}
            onChange={setLearningOutcomes}
          />
        </FormGroup>

        <FormGroup>
          <Label
            for="lessonProcedure"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Procedure
              <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <ReactQuill value={lessonProcedure} onChange={setLessonProcedure} style={{ marginTop: '10px' }} />
        </FormGroup>

        <FormGroup>
          <Label for="lessonClosure">
            <b>
              Closure
              <span style={{ color: 'red' }}>*</span>
            </b>
          </Label>
          <ReactQuill value={lessonClosure} onChange={setLessonClosure} />
        </FormGroup>
        <FormGroup>
        <Label for="addRubrics">
          
        </Label>
        </FormGroup>

        <div style={{ marginTop: '10px' }}>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{ marginLeft: '10px' }} className="custom-cancel-button" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddLessonModal;
